import * as React from "react";
import { Route } from 'react-router-dom';
import ManualOperations from "../manualOperations";
import AddClientToEvent from "../manualOperations/events/addClient";
import RemoveClientFronEvent from "../manualOperations/events/removeClient";
import AddClientToNewGeneralSchedule from "../manualOperations/generalSchedules/addClient";
import RemoveClientFromGeneralSchedule from "../manualOperations/generalSchedules/cancelSchedule";
import AddClientToPlan from "../manualOperations/plans/addClient";
import RemoveClientFromPlan from "../manualOperations/plans/removeClient";
import AddClientToSocialSchedule from "../manualOperations/socialSchedules/addClient";
import Reports from '../reports';
import EventReports from "../reports/eventReports";
import GeneralReports from "../reports/generalReports";
import SocialReports from "../reports/socialReports";
import AccessReports from "../reports/accessReports";
import ProfessionalReports from "../reports/professionalReports";
import FreeSchedulesReports from "../reports/generalReports/freeSchedulesReports";
import Marketing from "../marketing";
import FreeSocialSchedulesReports from "../reports/socialReports/freeSocialSchedulesReports";
import FreeEventsReports from "../reports/eventReports/freeEventsReports";
import CanceledSchedules from "../reports/canceledSchedules";
import PlansSchedulesReports from "../reports/plansReports/generalSchedules";
import EventContractReports from "../reports/eventReports/eventContracts";
import TriageSchedulesReports from "../reports/generalReports/triageSchedulesReports";

export default [
    <Route exact path="/relatorios" component={Reports} />,
    <Route exact path="/marketing" component={Marketing} />,
    <Route exact path="/relatorios/social" component={SocialReports} />,
    <Route exact path="/relatorios/social/gratuidade" component={FreeSocialSchedulesReports} />,
    <Route exact path="/relatorios/geral" component={GeneralReports} />,
    <Route exact path="/relatorios/geral/gratuidade" component={FreeSchedulesReports} />,
    <Route exact path="/relatorios/geral/triagem" component={TriageSchedulesReports} />,
    <Route exact path="/relatorios/eventos" component={EventReports} />,
    <Route exact path="/relatorios/eventos/gratuidade" component={FreeEventsReports} />,
    <Route exact path="/relatorios/eventos/contratos" component={EventContractReports} />,
    <Route exact path="/relatorios/acesso" component={AccessReports} />,
    <Route exact path="/relatorios/profissionais" component={ProfessionalReports} />,
    <Route exact path="/relatorios/cancelamentos" component={CanceledSchedules} />,
    <Route exact path="/relatorios/planos/geral" component={PlansSchedulesReports} />,
    <Route exact path="/manual-operations" component={ManualOperations} />,
    <Route exact path="/manual-operations/social-schedules/add-client" component={AddClientToSocialSchedule} />,
    <Route exact path="/manual-operations/plans/add-client" component={AddClientToPlan} />,
    <Route exact path="/manual-operations/plans/remove-client" component={RemoveClientFromPlan} />,
    <Route exact path="/manual-operations/events/add-client" component={AddClientToEvent} />,
    <Route exact path="/manual-operations/events/remove-client" component={RemoveClientFronEvent} />,
    <Route exact path="/manual-operations/general-schedules/add-client" component={AddClientToNewGeneralSchedule} />,
    <Route exact path="/manual-operations/general-schedules/remove-client" component={RemoveClientFromGeneralSchedule} />
];