import React, { useState, useCallback, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  SelectInput,
  SaveButton,
  Toolbar,
  ImageField,
  Labeled,
  FormDataConsumer,
  required,
  SelectArrayInput,
  BooleanInput
} from "react-admin";
import TinyMCEInput from "../helpers/TinyMCEInput";
import TagQuickCreateButton from "../custom/TagQuickCreateButton";
import axios from 'axios';
import { Constants } from '../../constants';

const PostEdit = (props) => {

  const [tagChoices, setTagChoices] = useState([]);
  const [professionalChoices, setProfessionalChoices] = useState([]);

  const getProfessionals = useCallback( async () => {
      try{
          const response = await axios.get(Constants.ApiUrl + '/users/professionals');
          
          const auxProfessionals = response.data;

          const transformedProfessionals = auxProfessionals.map((professional) => {
              return { id: professional.id, name: `${professional.name} ${professional.last_name}` };
          });

          setProfessionalChoices(transformedProfessionals);

      }catch(err){
          console.log(err);
      }
  }, []);

  const getTags =  useCallback( async () => {
    try{
        const response = await axios.get(Constants.ApiUrl + '/tags', { params: { range: '[0,1000]' } });

        console.log(response.data);
        const auxServices = response.data;

        const transformedTags = auxServices.map( (tag) => {
            return { id: tag.name, name: `${tag.name}` }
        })

        setTagChoices(transformedTags);

    }catch(err){
        console.log(err);
    }         
  }, []);

  useEffect(() => {
      getTags();
      getProfessionals();
  }, [getTags])

  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar" />
    </Toolbar>
  );

  const validateReq = required('Campo obrigatório');

  return (
    <Edit title="Editar post do blog" {...props}>
      <SimpleForm toolbar={<TranslatedToolbar/>}>
        <TextInput validate={validateReq} source="title" label="Título" />
        <ImageInput source="thumbnail" label="Thumbnail - (720x480)" accept="image/*" mulitple={false} placeholder={<p> Arraste e solte a imagem ou clique para alterar a thumbnail original </p>}>
            <ImageField source="thumbnail" title="Nova thumbnail" />
        </ImageInput>
        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Thumbnail original">
                            <ImageField source="thumbnail_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <TextInput validate={validateReq} source="abstract" label="Resumo" />
        <TextInput source="author_name" label="Nome do autor" />
        <TinyMCEInput source="body" label="Conteúdo" validate={required()} />
        <SelectInput source="author_id" label="Profissional Autor" choices={professionalChoices} />
        <div style={{display: "flex", flexDirection: "row"}}>
          <SelectArrayInput validate={validateReq} label="Tags" source="tags" choices={tagChoices} />
          <TagQuickCreateButton onChange={() => getTags()}/>
        </div>
        <SelectInput validate={validateReq} source="category" label="Categoria" choices={[
            { id: 'e-books', name: 'Ebooks' },
            { id: 'palestras', name: 'Palestras' },
            { id: 'filmes', name: 'Filmes' },
            { id: 'solidariedade', name: 'Solidariedade' },
            { id: 'empresarios', name: 'Empresários' },
            { id: 'projetos', name: 'Projetos' },
            { id: 'kids', name: 'Kids' },
            { id: 'espaços', name: 'Espaços' },
            { id: 'salas', name: 'Salas' },
            { id: 'depoimentos', name: 'Depoimentos' },
            { id: 'cafe', name: 'Café' },
        ]} />
        <BooleanInput source="comentable" label="Permitir comentários"/>
      </SimpleForm>
    </Edit>
  );
};

export default PostEdit;
