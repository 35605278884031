import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Constants } from "../../constants";

export function useRooms() {
  const [rooms, setRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [isRoomFetchingLoading, setRoomFetchingLoading] = useState(true);

  const selectedRoomsData = useMemo(
    () => rooms.filter((room) => selectedRooms.includes(room.id)),
    [rooms, selectedRooms]
  );

  const availableRooms = useMemo(
    () => rooms.filter((room) => !selectedRooms.includes(room.id)),
    [rooms, selectedRooms]
  );

  const getRooms = useCallback(async () => {
    setRoomFetchingLoading(true);
    try {
      const { data } = await axios.get(`${Constants.ApiUrl}/rooms`, {
        params: { range: "[0,1000]" },
      });
      setRooms(data);
    } catch (error) {
      console.error(error);
    } finally {
      setRoomFetchingLoading(false);
    }
  }, []);

  const addRoomOnSelected = useCallback((id) => {
    setSelectedRooms((rooms) => [...rooms, id]);
  }, []);

  const removeRoomOnSelected = useCallback((id) => {
    setSelectedRooms((rooms) => rooms.filter((room) => room !== id));
  }, []);

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  return {
    rooms,
    selectedRooms,
    availableRooms,
    selectedRoomsData,
    addRoomOnSelected,
    removeRoomOnSelected,
    isRoomFetchingLoading,
    setSelectedRooms,
    setRooms,
  };
}
