import React, { useCallback, useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  DeleteButton,
  DateField,
  ReferenceField,
  DateInput,
  SelectInput,
  Filter,
} from "react-admin";

import axios from "axios";
import { Constants } from "../../constants";

const SocialScheduleList = (props) => {
  const [serviceChoices, setServiceChoices] = useState([]);
  const [professionalChoices, setProfessionalChoices] = useState([]);

  const getServices = useCallback(async () => {
    try {
      const response = await axios.get(Constants.ApiUrl + "/services", {
        params: {
          range: "[0,1000]",
          sort: '["name", "ASC"]',
          filter: {
            type: "SOCIAL",
          },
        },
      });

      const auxServices = response.data;

      const transformedServices = auxServices.map((service) => {
        return { id: service.id, name: `${service.name}` };
      });

      setServiceChoices(transformedServices);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getProfessionals = useCallback(async () => {
    try {
      const response = await axios.get(
        Constants.ApiUrl + "/users/professionals"
      );

      const auxProfessionals = response.data;

      const transformedProfessionals = auxProfessionals.map((professional) => {
        return { id: professional.id, name: `${professional.name}` };
      });

      setProfessionalChoices(transformedProfessionals);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getServices();
    getProfessionals();
  }, [getProfessionals, getServices]);

  const ListFilter = (props) => (
    <Filter {...props}>
      <SelectInput
        source="type"
        label="Tipo"
        choices={[
          { id: "Online", name: "Online" },
          { id: "Presencial", name: "Presencial" },
        ]}
      />
      <DateInput source="date" label="Data" />
      <SelectInput source="service" label="Serviço" choices={serviceChoices} />
      <SelectInput
        source="professional"
        label="Profissional"
        choices={professionalChoices}
      />
      <SelectInput
        source="status"
        label="Status"
        choices={[
          { id: "Reservado", name: "Reservado" },
          { id: "Esgotado", name: "Esgotado" },
          { id: "Cancelado", name: "Cancelado" },
          { id: "Disponível", name: "Disponível" },
          { id: "Concluido", name: "Concluido" },
        ]}
      />
    </Filter>
  );

  return (
    <List
      title="Listar Agendamentos Socials"
      filters={<ListFilter />}
      {...props}
    >
      <Datagrid rowClick={"show"}>
        <TextField source="type" label="Tipo" />
        <DateField locales="pt-BR" showTime source="date" label="Data" />
        <ReferenceField source="service" label="Serviço" reference="services">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="professional"
          label="Profissional"
          reference="users"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" label="Status" />
        <TextField source="client_type" label="Ind/Grupo" />
        <NumberField source="max_clients" label="Maximo de clientes" />
        <NumberField source="scheduled_clients" label="Clientes agendados" />
        <EditButton basePath="/socialSchedules" />
        <DeleteButton basePath="/socialSchedules" />
      </Datagrid>
    </List>
  );
};

export default SocialScheduleList;
