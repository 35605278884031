import React, { useState } from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ImageField,
  ChipField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
  BooleanField,
  SelectField,
  DateField,
  UrlField,
} from "react-admin";

const AdvertisementShow = (props) => {

  const [ recordData, setRecordData ] = useState();

  const Title = ({ record }) => {
    setRecordData(record);
    return <span>Anúncio {record ? `"${record.name}"` : ''}</span>;
  };

  const ShowActions = ({ basePath, data, resource }) => (
      <CardActions>
          <ListButton basePath={basePath} record={data} />
          <EditButton basePath={basePath} record={data} />
          <DeleteButton basePath={basePath} record={data} />
          <RefreshButton basePath={basePath} record={data} />
      </CardActions>
  );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" label="Nome do anúncio" />
        <ImageField source="desktop_image_url" label="Imagem Desktop" />
        <ImageField source="mobile_image_url" label="Imagem Mobile" />
        <SelectField source="local" label="Local do anúncio" choices={[
            { id: 'home', name: 'Home' },
            { id: 'general-attendance', name: 'Agenda Geral' },
            { id: 'social-attendance', name: 'Agenda Social' },
            { id: 'events', name: 'Página de eventos/projetos' },
            { id: 'home-blog', name: 'Página principal Blog' },
            { id: 'single-post', name: 'Postagem' }
        ]} />
        <ChipField source="tags" label="Tags" />
        <SelectField source="type" label="Tipo" choices={[
            { id: 'service', name: 'Terapias' },
            { id: 'professional', name: 'Profissionais' },
            { id: 'event', name: 'Eventos' },
            { id: 'social', name: 'Agendamento Social' }
        ]} />
        {
          recordData && recordData.service ?
          <ReferenceField reference="services" source="service" label="Terapia anunciada">
            <TextField source="name" />
          </ReferenceField>
          : null
        }
        {
          recordData && recordData.professional ?
          <ReferenceField reference="users" source="professional" label="Profissional anunciado">
            <TextField source="name" />
          </ReferenceField>
          : null
        }
        {
          recordData && recordData.social ?
          <ReferenceField reference="socialSchedules" source="social" label="Social anunciado">
            <TextField source="name" />
          </ReferenceField>
          : null
        }
        {
          recordData && recordData.event ?
          <ReferenceField reference="events" source="event" label="Evento anunciado">
            <TextField source="name" />
          </ReferenceField>
          : null
        }
        <UrlField label="Link de referência" source="link" />
        <DateField locales="pt-BR" source="expires_at" label="Validade" />
        <BooleanField source="active" label="Inativo/Ativo" defaultValue={false}/>
      </SimpleShowLayout>
    </Show>
  );
};
  
  export default AdvertisementShow;
  