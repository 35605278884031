import React, {useEffect, useState, useCallback} from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  required,
  minValue,
  SelectArrayInput,
  choices,
  ImageField,
  FormDataConsumer,
  Labeled,
  ImageInput,
  DateInput
} from "react-admin";
import axios from 'axios';
import { Constants } from '../../constants';


const PlanEdit = (props) => {

    const [serviceChoices, setServiceChoices] = useState([]);
    const [professionalChoices, setProfessionalChoices] = useState([]);
    const [plansChoices, setPlansChoices] = useState([]);

    const getServices = useCallback( async () => {
        try{
            const response = await axios.get(Constants.ApiUrl + '/services', { params: { range: '[0,1000]', sort: `["id", "ASC"]` } });
            const auxServices = response.data;

            const transformedServices = auxServices.map( (service) => {
                return { id: service.id, name: `${service.name}` }
            })

            setServiceChoices(transformedServices);

        }catch(err){
            console.log(err);
        }         
    }, []);

    const getPlans = useCallback( async () => {
        try{
            const response = await axios.get(Constants.ApiUrl + '/plans', { params: { range: '[0,1000]', sort: `["name", "ASC"]` } });
            const auxPlans = response.data;

            const transformedPlans = auxPlans.map( (plan) => {
                return { id: plan.id, name: `${plan.name}` }
            })

            setPlansChoices(transformedPlans);

        }catch(err){
            console.log(err);
        }         
    }, []);

    const getProfessionals = useCallback( async () => {
        try{
            const response = await axios.get(Constants.ApiUrl + '/users/professionals');
            
            const auxProfessionals = response.data;

            const transformedProfessionals = auxProfessionals.map((professional) => {
                return { id: professional.id, name: `${professional.name} ${professional.last_name}` };
            });

            setProfessionalChoices(transformedProfessionals);

        }catch(err){
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getServices();
        getProfessionals();
        getPlans();
    }, [getProfessionals, getServices, getPlans])

    const dateParser = v => {
        // v is a string of "YYYY-MM-DD" format
        const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
        if (match === null) return;
        const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3], 23, 59);
        if (isNaN(d)) return;
        return d;
    };

    const validateReq = required('Campo obrigatório');
    const validateLimit = [required('Campo Obrigatório'), minValue(1, 'Não pode ser negativo')];
    const validateStatus = choices(['Ativo', 'Inativo'], 'Escolha o tipo');

    return (
    <Edit title="Editar plano" {...props}>
        <SimpleForm>
            <TextInput validate={validateReq} source="name" label="Nome"/>
            <ImageInput source="image" label="Thumbnail" accept="image/*" placeholder={<p> Arraste e solte ou clique para escolher a nova imagem </p>}>
                <ImageField source="image" label="Thumbnail" />
            </ImageInput>
            <FormDataConsumer>
                {({formData, dispatch, ...rest}) => {
                    if (!formData.src) {
                        return (
                            <div>
                            <Labeled label="Imagem Original">
                                <ImageField source="image_url" {...rest}/>
                            </Labeled>
                            </div>
                        );
                    }
                }}
            </FormDataConsumer>
            <TextInput validate={validateReq} source="description" label="Descrição"/>
            <NumberInput validate={validateLimit} source="quantity" label="Quantidade de consultas"/>
            <NumberInput validate={validateReq} source="price" lavel="Preço"/>
            <SelectArrayInput validate={validateReq} source="services" label="Serviços" choices={serviceChoices}/>
            <SelectArrayInput validate={validateReq} source="professionals" label="Profissionais" choices={professionalChoices}/>
            <SelectArrayInput source="can_upgrade_to" label="Possível upgrade para..." choices={plansChoices}/>
            <DateInput validate={validateReq} source="valid_until" label="Válido Até" parse={dateParser}/>
            <DateInput validate={validateReq} source="sold_until" label="Vendido Até" parse={dateParser}/>
            <SelectInput validate={validateStatus} source="status" label="Status" choices={[
                    { id: 'Ativo', name: 'Ativo' },
                    { id: 'Inativo', name: 'Inativo' },
                ]} />
        </SimpleForm>
    </Edit>
    );
};

export default PlanEdit;