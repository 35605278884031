import React, {useState, useCallback, useEffect} from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  SelectInput,
  SelectArrayInput,
  ImageField,
  SaveButton,
  Toolbar,
  required,
  choices
} from "react-admin";
import axios from 'axios';
import { Constants } from '../../constants';
import TagQuickCreateButton from '../custom/TagQuickCreateButton';

const ServiceCreate = (props) => {

  const [tagChoices, setTagChoices] = useState([]);

  const getTags =  useCallback( async () => {
    try{
        const response = await axios.get(Constants.ApiUrl + '/tags', { params: { range: '[0,1000]' } });

        console.log(response.data);
        const auxServices = response.data;

        const transformedTags = auxServices.map( (tag) => {
            return { id: tag.name, name: `${tag.name}` }
        })

        setTagChoices(transformedTags);

    }catch(err){
        console.log(err);
    }         
  }, []);

  useEffect(() => {
      getTags();
  }, [getTags])

  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar"/>
    </Toolbar>
  );

  const validateReq = required('Campo obrigatório');
  const validateStatus = [required('Campo obrigatório'), choices(['Ativo', 'Inativo'], 'Escolha o status da terapia')]
  const validateType = [required('Campo obrigatório'), choices(['SOCIAL', 'GERAL'], 'Escolha o tipo da terapia')]

  return (
    <Create title="Cadastrar novo serviço" {...props}>
      <SimpleForm redirect="list" toolbar={<TranslatedToolbar/>}>
        <TextInput validate={validateReq} source="name" label="Nome" />
        <ImageInput validate={validateReq} source="image" label="Imagem - (200x200)" accept="image/*" placeholder={<p> Arraste e solte ou clique para escolher a imagem </p>}>
            <ImageField source="image" title="Imagem" />
        </ImageInput>
        <TextInput multiline validate={validateReq} source="description" label="Descrição"/>
        <TextInput multiline source="indications_benefits" label="Indicações e beneficios"/>
        <div style={{display: "flex", flexDirection: "row"}}>
          <SelectArrayInput label="Tags" source="tags" choices={tagChoices} />
          <TagQuickCreateButton onChange={() => getTags()}/>
        </div>
        <SelectInput validate={validateType} source="type" label="Tipo" choices={[
            { id: 'SOCIAL', name: 'SOCIAL' },
            { id: 'GERAL', name: 'GERAL' },
        ]} />
        <SelectInput validate={validateStatus} source="status" label="Status" choices={[
            { id: 'Ativo', name: 'Ativo' },
            { id: 'Inativo', name: 'Inativo' },
        ]} />
      </SimpleForm>
    </Create>
  );
};

export default ServiceCreate;
