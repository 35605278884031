import React, { useState, useEffect, useCallback } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SaveButton,
  Toolbar,
  SelectArrayInput,
  ImageField,
  ImageInput,
  BooleanInput,
  DateTimeInput,
  FormDataConsumer,
  required,
} from "react-admin";
import TagQuickCreateButton from "../custom/TagQuickCreateButton";
import axios from 'axios';
import { Constants } from '../../constants';

const AdvertisementCreate = (props) => {

  const [tagChoices, setTagChoices] = useState([]);
  const [serviceChoices, setServiceChoices] = useState([]);
  const [professionalChoices, setProfessionalChoices] = useState([]);
  const [eventChoices, setEventChoices] = useState([]);
  const [socialSchedulesChoices, setSocialSchedulesChoices] = useState([]);

  const getEvents = useCallback (async () => {
    try{
      const response = await axios.get(Constants.ApiUrl + '/events', { params: { range: '[0,1000]', filter: {"status": "Disponível"} } });

      const auxEvents = response.data;

      const transformedEvents = auxEvents.map( (event) => {
          return { id: event.id, name: `${event.name}` }
      })

      setEventChoices(transformedEvents);

    }catch(err){
        console.log(err);
    }  
  }, []);

  const getServices = useCallback( async () => {
      try{
          const response = await axios.get(Constants.ApiUrl + '/services', { params: { range: '[0,1000]' } });

          const auxServices = response.data;

          const transformedServices = auxServices.map( (service) => {
              return { id: service.id, name: `${service.name}` }
          })

          setServiceChoices(transformedServices);

      }catch(err){
          console.log(err);
      }         
  }, []);

  const getProfessionals = useCallback( async () => {
      try{
          const response = await axios.get(Constants.ApiUrl + '/users/professionals');
          
          const auxProfessionals = response.data;

          const transformedProfessionals = auxProfessionals.map((professional) => {
              return { id: professional.id, name: `${professional.name} ${professional.last_name}` };
          });

          setProfessionalChoices(transformedProfessionals);

      }catch(err){
          console.log(err);
      }
  }, []);

  const getSocialSchedules = useCallback( async () => {

    const user = JSON.parse(localStorage.getItem('auth'));

    const config = {
        params: {
            range: '[0,50]',
            filter: {"status": "Disponível"}
        },
        headers: {
            'Authorization': `Bearer ${user.token}`
        },
    }

    try{
        const response = await axios.get(Constants.ApiUrl + '/socialSchedules', config);
        
        const auxSocials = response.data;

        const socials = auxSocials.map((social) => {
            return { 
                id: social.id, 
                name: `${social.type} ${social.socialScheduleService.name} ${new Date(social.date).toLocaleString('pt-BR', {dateStyle: 'short',})} ${new Date(social.date).toLocaleString('pt-BR', {timeStyle: 'short', hour12: false,})}` };
        });

        setSocialSchedulesChoices(socials);

    }catch(err){
        console.log(err);
    }
  },[]);

  const getTags =  useCallback( async () => {
    try{
        const response = await axios.get(Constants.ApiUrl + '/tags', { params: { range: '[0,1000]' } });
        const auxServices = response.data;
        const transformedTags = auxServices.map( (tag) => {
            return { id: tag.name, name: `${tag.name}` }
        })
        setTagChoices(transformedTags);
    }catch(err){
        console.log(err);
    }         
  }, []);

  useEffect(() => {
      getTags();
      getServices();
      getProfessionals();
      getEvents();
      getSocialSchedules();
  }, [getTags, getServices, getProfessionals, getEvents, getSocialSchedules])

  const validateReq = required('Campo obrigatório');

  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar"/>
    </Toolbar>
  );

  return (
    <Create title="Novo post do blog" {...props}>
      <SimpleForm redirect="list" toolbar={<TranslatedToolbar/>}>
        <TextInput validate={validateReq} source="name" label="Nome do anúncio" />
        <ImageInput validate={validateReq} source="desktopImage" label="Imagem Desktop - (900x250)" accept="image/*" placeholder={<p> Arraste e solte ou clique para escolher a imagem </p>}>
            <ImageField source="desktopImage" title="Imagem Desktop" />
        </ImageInput>
        <ImageInput validate={validateReq} source="mobileImage" label="Imagem Mobile - (400x250)" accept="image/*" placeholder={<p> Arraste e solte ou clique para escolher a imagem </p>}>
            <ImageField source="mobileImage" title="Imagem Mobile" />
        </ImageInput>
        <SelectInput validate={validateReq} source="local" label="Local do anúncio" choices={[
            { id: 'home', name: 'Home' },
            { id: 'general-attendance', name: 'Agenda Geral' },
            { id: 'social-attendance', name: 'Agenda Social' },
            { id: 'events', name: 'Página de eventos/projetos' },
            { id: 'home-blog', name: 'Página principal Blog' },
            { id: 'single-post', name: 'Postagem' },
            { id: 'plans', name: 'Planos' },
        ]} />
        <div style={{display: "flex", flexDirection: "row"}}>
          <SelectArrayInput label="Tags" source="tags" choices={tagChoices} />
          <TagQuickCreateButton onChange={() => getTags()}/>
        </div>
        <SelectInput validate={validateReq} source="type" label="Tipo" choices={[
            { id: 'service', name: 'Terapias' },
            { id: 'professional', name: 'Profissionais' },
            { id: 'event', name: 'Eventos' },
            { id: 'social', name: 'Agendamento Social' },
            { id:  'generic', name: 'Anúncio Genérico ' }
        ]} />
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'generic' &&
            <TextInput validate={validateReq} source="genericLink" label="Link do anúncio" {...rest} />
        }
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'professional' &&
            <SelectInput validate={validateReq} source="professional" label="Profissional Anunciado" choices={professionalChoices} {...rest} />
        }
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'service' &&
            <SelectInput validate={validateReq} source="service" label="Terapia anunciada" choices={serviceChoices} {...rest} />
        }
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'event' &&
            <SelectInput validate={validateReq} source="event" label="Evento anunciado" choices={eventChoices} {...rest} />
        }
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'social' &&
            <SelectInput validate={validateReq} source="social" label="Social anunciado" choices={socialSchedulesChoices} {...rest} />
        }
        </FormDataConsumer>
        <DateTimeInput locales="pt-BR" source="expires_at" label="Validade" />
        <BooleanInput source="active" label="Inativo/Ativo" defaultValue={false}/>
      </SimpleForm>
    </Create>
  );
};

export default AdvertisementCreate;
