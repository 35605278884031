import React from "react";
import CardActions from "@material-ui/core/CardActions";

import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  ListButton,
  EditButton,
  RefreshButton,
  BooleanField,
} from "react-admin";

const InvoiceTaxShow = (props) => {
  const Title = ({ record }) => {
    return <span>Taxas {record ? `"${record.category}"` : ""}</span>;
  };

  const ShowActions = ({ basePath, data, resource }) => (
    <CardActions>
      <ListButton basePath={basePath} record={data} />
      <EditButton basePath={basePath} record={data} />
      <RefreshButton basePath={basePath} record={data} />
    </CardActions>
  );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID da Taxa" />
        <TextField source="category" label="Categoria" />
        <BooleanField source="retain_iss" label="Reter ISS" />
        <NumberField source="iss" label="ISS" />
        <NumberField source="cofins" label="Cofins" />
        <NumberField source="csll" label="CSLL" />
        <NumberField source="ir" label="IR" />
        <NumberField source="inss" label="INSS" />
        <NumberField source="pis" label="PIS" />
        <TextField source="municipal_service_id" label="ID Serviço Municipal" />
        <TextField
          source="municipal_service_code"
          label="Código Serviço Municipal"
        />
        <TextField
          source="municipal_service_name"
          label="Nome Serviço Municipal"
        />
        <TextField source="category" label="Categoria" />
        <BooleanField source="active" label="Emissão ativa" />
      </SimpleShowLayout>
    </Show>
  );
};

export default InvoiceTaxShow;
