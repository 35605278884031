import {Constants } from './constants';

const apiUrl = Constants.ApiUrl;

const authProvider = {
    login: async (email, password) =>  {
        const request = new Request(apiUrl +'/auth/login', {
            method: 'POST',
            body: JSON.stringify(email, password),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if(auth.role === 'client' || auth.role === 'professional'){
                    throw new Error('Você não tem autorização para acessar essa página');
                }
                localStorage.setItem('auth', JSON.stringify(auth));
                return Promise.resolve('Login realizado com sucesso');
            })
            .catch((err) => {
                if(err.message === 'Você não tem autorização para acessar essa página'){
                    return Promise.reject({ message: 'Você não tem autorização para acessar essa página' });
                }else{
                    return Promise.reject({ message: 'Usuário ou senha incorretos.' });
                }
            });
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            // localStorage.removeItem('auth');
            return Promise.resolve({ message: 'Erro de autorização na API' });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },

    checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject({ message: 'Você precisa estar logado para acessar esta página' }),
    

    getIdentity: () => {
        try {
            const { id, userName } = JSON.parse(localStorage.getItem('auth'));
            if(userName === undefined){
                return Promise.reject();
            }
            const fullName = userName;
            return Promise.resolve({ id, fullName });
        } catch (error) {
            return Promise.reject();
        }
    },

    getPermissions: () => {
        return Promise.resolve();
    },

    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
};

export default authProvider