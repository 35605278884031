import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import './styles.css';

const Reports = () => {

    const history = useHistory();

    return (
        <div id="relatorios" >
            <header>
                <h1>Relatórios</h1>
            </header>
            <section className="reports-area">
                <h2>Relatórios Mundo Akar Social</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/social')}}
                    >
                        Relatórios social
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/social/gratuidade')}}
                    >
                        Gratuidade
                    </Button>
                </div>
                <h2>Relatórios Mundo Akar Geral</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/geral')}}
                    >
                        Relatórios geral
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/geral/gratuidade')}}
                    >
                        Gratuidade
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/geral/triagem')}}
                    >
                        Triagem
                    </Button>
                </div>
                <h2>Relatórios Eventos</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/eventos')}}
                    >
                        Relatórios eventos
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/eventos/gratuidade')}}
                    >
                        Gratuidade
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/eventos/contratos')}}
                    >
                        Contratos
                    </Button>
                </div>
                <h2>Relatório de acesso às páginas</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/acesso')}}
                    >
                        Relatórios acesso
                    </Button>
                </div>
                <h2>Relatório de Profissionais</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/profissionais')}}
                    >
                        Acessar
                    </Button>
                </div>
                <h2>Relatório de Cancelamentos</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/cancelamentos')}}
                    >
                        Acessar
                    </Button>
                </div>
                <h2>Relatório de Planos</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="report-button"
                        onClick={() => {history.push('/relatorios/planos/geral')}}
                    >
                        Acessar
                    </Button>
                </div>
            </section>
        </div>
    )
}

export default Reports;