import React, { useCallback, useEffect, useState } from "react";
import {
  getDate,
  getYear,
  getMinutes,
  getHours,
  getMonth,
  parseISO,
} from "date-fns";
import { Button, Modal } from "@material-ui/core";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  DateInput,
  Filter,
  SimpleForm,
  TextInput,
  SelectInput,
  SaveButton,
  Toolbar,
  useNotify,
  useRefresh,
  UrlField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";
import { Constants } from "../../constants";

const SocialSchedulesClientsList = (props) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      top: "30%",
      left: "35%",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();

  const notify = useNotify();
  const refresh = useRefresh();
  const [modalState, setModalState] = useState(false);
  const [scheduleToCancel, setScheduleToCancel] = useState({});
  const [modalType, setModalType] = useState();
  const [scheduleToReschedule, setScheduleToReschedule] = useState({});
  const [serviceChoices, setServiceChoices] = useState([]);
  const [professionalChoices, setProfessionalChoices] = useState([]);
  const [userToReschedule, setUserToReschedule] = useState({});
  const [schedulesChoices, setSchedulesChoices] = useState([]);
  const [schduleToAddClient, setScheduleToAddClient] = useState({});
  const [chosenProfessional, setChosenProfessional] = useState({});
  const [chosenDate, setChosenDate] = useState();
  const [socialSchedule, setSocialSchedule] = useState({});
  const [chosenService, setChosenService] = useState({});
  const [professionalsFilter, setProfessionalsFilter] = useState([]);

  const parseMinuteTo00 = (minute) => {
    return (minute < 10 ? "0" : "") + minute;
  };

  const getServices = useCallback(async () => {
    try {
      const response = await axios.get(Constants.ApiUrl + "/services", {
        params: {
          range: "[0,1000]",
          sort: '["name", "ASC"]',
          filter: {
            type: "SOCIAL",
          },
        },
      });
      const auxServices = response.data;

      const transformedServices = auxServices.map((service) => {
        return { id: service.id, name: `${service.name}` };
      });

      setServiceChoices(transformedServices);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getUser = useCallback(async (schedule) => {
    const user = JSON.parse(localStorage.getItem("auth"));
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    try {
      const response = await axios.get(
        `${Constants.ApiUrl}/users/${schedule.user_id}`,
        config
      );
      setUserToReschedule(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getSocialSchedule = useCallback(async (schedule) => {
    try {
      const response = await axios.get(
        `${Constants.ApiUrl}/socialSchedules/${schedule.social_schedule_id}`
      );
      setSocialSchedule(response.data);
      // setScheduleDetails(response.data.date);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getProfessionals = useCallback(async () => {
    const user = JSON.parse(localStorage.getItem("auth"));

    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      params: {
        range: ``,
        sort: `["name", "ASC"]`,
        filter: {
          role: "professional",
        },
      },
    };
    await axios
      .get(`${Constants.ApiUrl}/users`, config)
      .then((response) => {
        setProfessionalsFilter(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getServices();
    getProfessionals();
  }, [getServices, getProfessionals]);

  useEffect(() => {
    getUser(scheduleToReschedule);
    setChosenService(undefined);
    setChosenDate(undefined);
    setChosenProfessional(undefined);
    setScheduleToAddClient(undefined);
  }, [scheduleToReschedule]);

  useEffect(() => {
    setServiceChoices(undefined);
    setProfessionalChoices(undefined);
    setSchedulesChoices(undefined);
  }, [chosenDate]);

  const handleModalOpen = () => {
    setModalState(true);
  };

  const handleModalClose = () => {
    setModalState(false);
  };

  const ListFilter = (props) => (
    <Filter {...props}>
      <DateInput source="date" label="Data da consulta" />
      <DateInput source="createdAt" label="Data da compra" />
      <SelectInput
        source="professional"
        choices={professionalsFilter}
        label="Profissional"
      />
      <TextInput source="client" label="Cliente" />
    </Filter>
  );

  const handleCancelSchedule = useCallback(async (record) => {
    setScheduleToCancel(record);
    setModalType("cancel");
    handleModalOpen();
  }, []);

  const handleReschedule = useCallback(async (record) => {
    setScheduleToReschedule(record);
    await getSocialSchedule(record);
    setModalType("reschedule");
    handleModalOpen();
  }, []);

  const handleDateChange = useCallback(async (date) => {
    const day = getDate(parseISO(date.target.value));
    const month = getMonth(parseISO(date.target.value));
    const year = getYear(parseISO(date.target.value));

    setChosenDate(parseISO(date.target.value));
    await axios
      .get(`${Constants.ApiUrl}/socialSchedules/services/`, {
        params: { day: day, month: month + 1, year: year },
      })
      .then((response) => {
        setServiceChoices(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleServiceChange = useCallback(async (e, date) => {
    const day = getDate(date);
    const month = getMonth(date);
    const year = getYear(date);
    console.log(day, month, year);
    console.log(e.target.value);
    const service = e.target.value;

    await axios
      .get(`${Constants.ApiUrl}/socialSchedules/professionals/${service}`, {
        params: { day: day, month: month + 1, year: year },
      })
      .then((response) => {
        setChosenService(service);
        setProfessionalChoices(response.data);
      })
      .catch((err) => {
        console.log(err);
        setChosenService(undefined);
      });
  }, []);

  const handleProfessionalChange = useCallback(async (prof, date, service) => {
    const day = getDate(date);
    const month = getMonth(date);
    const year = getYear(date);
    const professional = prof.target.value;
    setChosenProfessional(professional);
    await axios
      .get(`${Constants.ApiUrl}/socialSchedules/professional/${professional}`, {
        params: { day: day, month: month + 1, year: year, service: service },
      })
      .then((response) => {
        const auxChoices = response.data;

        const transformedSchedules = auxChoices
          .filter((toFilter) => {
            return toFilter.status === "Disponível";
          })
          .map((schedule) => {
            const auxDay = getDate(parseISO(schedule.date));
            const auxMonth = getMonth(parseISO(schedule.date));
            const auxYear = getYear(parseISO(schedule.date));
            const auxMinute = getMinutes(parseISO(schedule.date));
            const auxHours = getHours(parseISO(schedule.date));
            return {
              id: schedule.id,
              name: `${schedule.type} - ${auxDay}/${
                auxMonth + 1
              }/${auxYear} @${auxHours}:${parseMinuteTo00(auxMinute)}`,
            };
          });

        setSchedulesChoices(transformedSchedules);
      })
      .catch((err) => {
        console.log(err);
        setChosenProfessional(undefined);
      });
  }, []);

  const handleScheduleToAddChange = useCallback((schedule) => {
    setScheduleToAddClient(schedule.target.value);
  });

  const handleSubmitReschedule = async (modalData) => {
    const user = JSON.parse(localStorage.getItem("auth"));
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios
      .post(
        Constants.ApiUrl + "/socialSchedules/reschedule",
        {
          client: userToReschedule.id,
          socialScheduleToRemoveClientId:
            scheduleToReschedule.social_schedule_id,
          socialScheduleToAddClientId: modalData.scheduleToAdd,
        },
        config
      )
      .then(() => {
        handleModalClose();
        notify("Agendamento remarcado com sucesso", "info", {});
        refresh();
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Ocorreu um erro ao tentar remarcar o agendamento",
          "warning",
          {}
        );
      });
  };

  const handleSubmitCancelSchedule = async (modalData) => {
    const user = JSON.parse(localStorage.getItem("auth"));
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios
      .patch(
        Constants.ApiUrl + "/socialSchedules/admin/cancel",
        {
          client: scheduleToCancel.user_id,
          socialSchedulesId: scheduleToCancel.social_schedule_id,
          reason: modalData.reason,
          cancelBy: modalData.cancelBy,
        },
        config
      )
      .then(() => {
        handleModalClose();
        notify("Agendamento cancelado com sucesso", "info", {});
        refresh();
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Ocorreu um erro ao tentar cancelar o agendamento",
          "warning",
          {}
        );
      });
  };

  const CancelScheduleButton = ({ record }) => {
    return (
      <Button color="secondary" onClick={() => handleCancelSchedule(record)}>
        Cancelar
      </Button>
    );
  };

  const RescheduleButton = ({ record }) => {
    return (
      <Button color="secondary" onClick={() => handleReschedule(record)}>
        Remarcar
      </Button>
    );
  };

  const ToolbarCancelSchedule = (props) => (
    <Toolbar {...props}>
      <SaveButton onSave={handleSubmitCancelSchedule} label="Confirmar" />
    </Toolbar>
  );

  const ToolbarReschedule = (props) => (
    <Toolbar {...props}>
      <SaveButton onSave={handleSubmitReschedule} label="Confirmar" />
    </Toolbar>
  );

  const bodyCancelSchedule = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Confirme o cancelamento do agendamento</h2>
      <SimpleForm submitOnEnter={false} toolbar={<ToolbarCancelSchedule />}>
        <TextInput source="reason" label="Motivo do cancelamento" />
        <TextInput source="cancelBy" label="Quem cancelou" />
      </SimpleForm>
    </div>
  );

  const bodyReschedule = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">
        Escolha o serviço, dia e consulta para a remarcação
      </h2>
      <SimpleForm submitOnEnter={false} toolbar={<ToolbarReschedule />}>
        <span>Usuário: {userToReschedule.name}</span>
        <span>
          Data da consulta: {getDate(parseISO(socialSchedule.date))}/
          {getMonth(parseISO(socialSchedule.date)) + 1}/
          {getYear(parseISO(socialSchedule.date))} às{" "}
          {getHours(parseISO(socialSchedule.date))}:
          {parseMinuteTo00(getMinutes(parseISO(socialSchedule.date)))}
        </span>
        <DateInput
          onChange={(date) => {
            handleDateChange(date);
          }}
          source="date"
          label="Data"
        />
        {chosenDate && (
          <SelectInput
            onChange={(service) => {
              handleServiceChange(service, chosenDate);
            }}
            source="service"
            label="Serviço"
            choices={serviceChoices}
          />
        )}
        {chosenService && (
          <SelectInput
            onChange={(professional) => {
              handleProfessionalChange(professional, chosenDate, chosenService);
            }}
            source="professional"
            label="Profissional"
            choices={professionalChoices}
          />
        )}
        {chosenProfessional && (
          <SelectInput
            onChange={(schedule) => {
              handleScheduleToAddChange(schedule);
            }}
            source="scheduleToAdd"
            label="Consulta"
            choices={schedulesChoices}
          />
        )}
      </SimpleForm>
    </div>
  );

  return (
    <List
      title="Lista dos clientes agendados"
      filters={<ListFilter />}
      {...props}
    >
      <>
        <Modal
          open={modalState}
          onClose={handleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalType === "cancel" ? bodyCancelSchedule : bodyReschedule}
        </Modal>
        <Datagrid>
          <TextField source="id" label="ID" />
          <ReferenceField
            source="user_id"
            label="Cliente"
            reference="users"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="social_schedule_id"
            label="Profissional"
            reference="socialSchedules"
          >
            <ReferenceField
              source="professional"
              label="Profissional"
              reference="users"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
          <DateField
            locales="pt-BR"
            showTime
            source="createdAt"
            label="Data da compra"
          />
          <ReferenceField
            source="social_schedule_id"
            label="Data da consulta"
            reference="socialSchedules"
            link="show"
          >
            <DateField locales="pt-BR" showTime source="date" />
          </ReferenceField>
          <ReferenceField
            source="social_schedule_id"
            label="Link"
            reference="socialSchedules"
            link="show"
          >
            <UrlField source="zoom_url" />
          </ReferenceField>
          <RescheduleButton label="Remarcar" />
          <CancelScheduleButton label="Cancelar" />
        </Datagrid>
      </>
    </List>
  );
};

export default SocialSchedulesClientsList;
