import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  EditButton,
  RefreshButton
} from "react-admin";

const GaleryShow = (props) => {

    const Title = ({ record }) => {
        return <span>Galeria {record ? `"${record.id}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <EditButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );
  
    return (
      <Show title={<Title />} actions={<ShowActions />} {...props}>
        <SimpleShowLayout rowClick={'edit'}>
          <TextField source="id" label="ID" />
          <TextField source="main_video_url" label="Video pricipal" />
          <TextField source="video2_url" label="Video 2" />
          <TextField source="video3_url" label="Video 3" />
          <TextField source="video4_url" label="Video 4" />
          <TextField source="video5_url" label="Video 5" />
          <TextField source="main_text" label="Texto Principal" />
          {/* <ImageField source="image1_url" label="Imagem 1"/>
          <ImageField source="image2_url" label="Imagem 2"/>
          <ImageField source="image3_url" label="Imagem 3"/>
          <ImageField source="image4_url" label="Imagem 4"/>
          <ImageField source="image5_url" label="Imagem 5"/>
          <ImageField source="image6_url" label="Imagem 6"/> */}
        </SimpleShowLayout>
      </Show>
    );
  };
  
  export default GaleryShow;
  