import React, {useEffect, useCallback, useState} from "react";
import {
  List,
  Datagrid,
  DeleteButton,
  DateField,
  ReferenceField,
  TextField,
  Filter,
  SelectInput
} from "react-admin";

import axios from 'axios';
import { Constants } from '../../constants';

const WaitList = (props) => {

    const [serviceChoices, setServiceChoices] = useState([]);
    const [professionalChoices, setProfessionalChoices] = useState([]);

    const getServices = useCallback( async () => {
        try{
            const response = await axios.get(Constants.ApiUrl + '/services', { params: { range: '[0,1000]' } });
            const auxServices = response.data;

            const transformedServices = auxServices.map( (service) => {
                return { id: service.id, name: `${service.name}` }
            })

            setServiceChoices(transformedServices);

        }catch(err){
            console.log(err);
        }         
    }, []);

    const getProfessionals = useCallback( async () => {
        try{
            const response = await axios.get(Constants.ApiUrl + '/users/professionals');
            
            const auxProfessionals = response.data;

            const transformedProfessionals = auxProfessionals.map((professional) => {
                return { id: professional.id, name: `${professional.name}` };
            });

            setProfessionalChoices(transformedProfessionals);
        }catch(err){
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getServices();
        getProfessionals();
    }, [getProfessionals,getServices])

    const ListFilter = props => (
        <Filter {...props}>
            <SelectInput source="service" label="Serviço" choices={serviceChoices} />
            <SelectInput source="professional" label="Profissional" choices={professionalChoices}/>
        </Filter>
      );
    
    return (
    <List title="Lista de espera" filters={<ListFilter/>} {...props}>
        <Datagrid>
        <ReferenceField source="client" label="Cliente" reference="users" link={false}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="professional" label="Profissional de interesse" reference="users" link={false}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="service" label="Terapia de interesse" reference="services" link={false}>
            <TextField source="name"/>
        </ReferenceField>
        <DateField locales="fr-FR" showTime source="createdAt" label="Entrou na lsta em" />
        <DeleteButton basePath="/waitlistclients" />
        </Datagrid>
    </List>
    );

}

export default WaitList;

