import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  DeleteButton,
  Filter,
  SelectInput,
  TextInput,
  DateField,
  DateInput
} from "react-admin";

const PostList = (props) => {

  const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="title" label="Título" />
        <TextInput source="description" label="Descrição"/>
        <SelectInput source="category" label="Categoria" choices={[
            { id: 'e-books', name: 'Ebooks' },
            { id: 'palestras', name: 'Palestras' },
            { id: 'filmes', name: 'Filmes' },
            { id: 'solidariedade', name: 'Solidariedade' },
            { id: 'empresarios', name: 'Empresários' },
            { id: 'projetos', name: 'Projetos' },
            { id: 'kids', name: 'Kids' },
            { id: 'espaços', name: 'Espaços' },
            { id: 'salas', name: 'Salas' },
            { id: 'cafe', name: 'Café' },
        ]} />
        <DateInput source="createdAt" locales="fr-FR" label="Publicado em"/>
    </Filter>
  );

  return (
    <List title="Listar posts do blog" filters={<ListFilter />} {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="Id" />
        <TextField source="title" label="Título" />
        <TextField source="author_name" label="Autor" />
        <TextField source="abstract" label="Resumo" />
        <ChipField source="category" label="Categoria" />
        <DateField locales="fr-FR" source="createdAt" label="Publicado em"/>
        <EditButton basePath="/posts" />
        <DeleteButton basePath="/posts" />
      </Datagrid>
    </List>
  );
};

export default PostList;
