import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Button, TextField } from '@material-ui/core';
import { useNotify } from 'react-admin';
import DateFnsUtils from '@date-io/date-fns';
import { pt } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';
import { Constants } from '../../../../constants';

import './styles.css';

const AddClientToNewGeneralSchedule = () => {

    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();

    const [clients, setClients] = useState([]);
    const [services, setServices] = useState([]);
    const [professionals, setProfessionals] = useState([]);
    const [schedules, setSchedules] = useState([]);

    const [selectedClient, setSelectedClient] = useState({});
    const [selectedService, setSelectedService] = useState({});
    const [selectedProfessional, setSelectedProfessional] = useState({});
    const [selectedSchedule, setSelectedSchedule] = useState({});
    const [selectedDate, setSelectedDate] = useState();

    const getClients = async () => {

        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                range: "[0, 15000]",
                sort: `["name", "ASC"]`,
                filter: {}
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/users`, config)
            .then((response) => {

                setClients(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getServices = async () => {
        setIsLoading(true);

        await Axios.get(`${Constants.ApiUrl}/user-services/available`)
            .then((response) => {
                setServices(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getProfessionals = async (service) => {
        setIsLoading(true);

        await Axios.get(`${Constants.ApiUrl}/services/${service}/professionals`)
            .then((response) => {
                const auxData = response.data;

                auxData.forEach(data => {
                    setProfessionals(data.serviceProfessionals);
                });
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getSchedules = async (date, professional) => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                day: new Date(date).getDate(),
                month: new Date(date).getMonth(),
                year: new Date(date).getFullYear(),
                service: selectedService
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/schedules/availability/day/${professional.id}`, config)
            .then((response) => {
                const data = response.data;
                const auxSchedules = data.map((schedule, index) => {
                    return {
                        id: index,
                        schedule: schedule,
                        date: new Date(schedule.date).toLocaleString('pt-BR', {
                            dateStyle: 'short',
                        }),
                        hour: new Date(schedule.date).toLocaleString('pt-BR', {
                            timeStyle: 'short',
                            hour12: false,
                        }),
                        available: schedule.available,
                        status: schedule.status,
                        type: schedule.type,
                    }
                });
                setSchedules(auxSchedules);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });

    }

    const handleSelectedService = (service) => {
        setSelectedService(service ? service.id : null);
        if(service){
            getProfessionals(service.id);
        }
    }

    const handleSelectedProfessional = (professional) => {
        setSelectedProfessional(professional);
    }

    const handleDateChange = (date) => {
        setSelectedDate(date)
        getSchedules(date, selectedProfessional);
    }

    const handleSelectedSchedule = (schedule) => {
        setSelectedSchedule(schedule ? schedule.schedule : null);
    }

    const handleSubmitAddClientToGeneralSchedule = async () => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {

            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.post(`${Constants.ApiUrl}/schedules/admin/addclient`, {
            client: selectedClient.id,
            schedulesToConfirm: [
                {
                    available: selectedSchedule.available,
                    date: selectedSchedule.date,
                    price: selectedSchedule.price,
                    type: selectedSchedule.type,
                    professional: selectedSchedule.professional,
                    service: selectedService
                }
            ],
        } ,config)
            .then((response) => {
                setIsLoading(false);
                notify(response.data.message, 'info', {});
            })
            .catch((err) => {
                console.log(err);
                notify(err.response.data.message, 'warning', {});
                setIsLoading(false);
            });

        setIsLoading(false);
    }

    useEffect(() => {
        getClients();
        getServices();
    }, [])

    return (
        <div id="add-client-to-general-schedule" >
            <header>
                <h1>Agendar cliente em um agendamento Geral</h1>
            </header>
            <section>
                <h2>Selecionar cliente</h2>
                <Autocomplete
                    id="select-client"
                    options={clients}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    style={{width: 300}}
                    loading={isLoading}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Cliente"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {setSelectedClient(value)}}
                />
            </section>
            <section>
                <h2>Selecionar terapia</h2>
                <Autocomplete
                    id="select-service"
                    options={services}
                    getOptionLabel={(option) => option.name}
                    style={{width: 300}}
                    loading={isLoading}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Terapia"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedService(value)}}
                />
            </section>
            <section>
                <h2>Selecionar Profissional</h2>
                <Autocomplete
                    id="select-professional"
                    options={professionals}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    style={{width: 300}}
                    loading={isLoading}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Profissional"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedProfessional(value)}}
                />
            </section>
            <section>
                <h2>Selecionar Dia</h2>
                <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Selecione o dia"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </section>
            <section>
                <h2>Selecionar Horário</h2>
                <Autocomplete
                    id="select-schedule"
                    options={schedules}
                    getOptionLabel={(option) => `${option.type} ${option.date} ${option.hour}`}
                    getOptionDisabled={(option) => !option.available }
                    style={{width: 300}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Horário"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedSchedule(value)}}
                />
            </section>
            <div className="button-box">
                <Button disabled={!selectedClient || !selectedSchedule || !selectedProfessional} onClick={() => {handleSubmitAddClientToGeneralSchedule()}}>Agendar cliente</Button>
            </div>
        </div>
    )
}

export default AddClientToNewGeneralSchedule;