import React, { useEffect, useState } from 'react';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { DataGrid } from '@material-ui/data-grid';
import { useNotify } from 'react-admin';
import TinyMCEInput from "../helpers/TinyMCEInput";
import { SimpleForm, Toolbar } from 'react-admin';
import Axios from 'axios';
import defaultEmail from './defaultEmail';
import { gridTranslations } from '../custom/gridTranslations';

import './styles.css';
import { Constants } from '../../constants';

const Marketing = () => {

    const [clients, setClients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedClients, setSelectedClients] = useState();
    const [selectedAdvFilter, setSelectedAdvFilter] = useState();
    const [mkEmail, setMkEmail] = useState();
    const [subject, setSubject] = useState();
    const notify = useNotify();

    const columns = [
        { field : 'name', headerName: 'Nome', width: 160 },
        { field : 'last_name', headerName: 'Sobre Nome', width: 180 },
        { field : 'email', headerName: 'Email', width: 250 },
        { field : 'role', headerName: 'Função', width: 110 },
        { field : 'state', headerName: 'UF', width: 80, hide: true },
        { field : 'city', headerName: 'Cidade', hide: true },
        { field : 'zip_code', headerName: 'CEP', width: 160, hide: true },
        { field : 'phone', headerName: 'Telefone', hide: true },
        { field : 'cpf_cnpj', headerName: 'CPF/CNPJ', hide: true },
        { field : 'company_name', headerName: 'Nome da Empresa', hide: true },
        { field : 'employee_quantity', headerName: 'Funcionários', hide: true, type: 'number' },
        { field : 'active', headerName: 'Ativo', hide: true, type: 'boolean' },
        { field : 'notify', headerName: 'Notificações', hide: true, type: 'boolean' },
        { field : 'comission', headerName: 'Comissão', hide: true, type: 'number' },
        { field : 'createdAt', headerName: 'Criação', width: 150, hide: true, type: 'dateTime' },
    ];

    const getClients = async () => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                range: "[0, 15000]",
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/users`, config)
            .then((response) => {
                setClients(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleSelectAdvFilterChange = async (event) => {
        setIsLoading(true);
        setSelectedAdvFilter(event.target.value)

        const filter = event.target.value;

        const user = JSON.parse(localStorage.getItem('auth'));
    
        const config = {
            params: {
                range: "[0, 15000]",
                // sort: `["name", "ASC"]`,
                filter: {
                    "advanced": filter
                }
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/users`, config)
            .then((response) => {
                console.log(response.data);
                setClients(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleUsersSelection = (value) => {
        setSelectedClients(value);
    }

    const handleEmailMake = (value) => {
        setMkEmail(value);
    }

    const handleSubmitMarketingEmail = async () => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        const body = {
            subject: subject,
            clients: selectedClients,
            email: mkEmail
        }

        await Axios.post(`${Constants.ApiUrl}/marketing`, body, config)
            .then((response) => {
                notify(response.data.message, 'info', {});
                // window.location.reload(); // perguntar pra cliente
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notify(err.response.data.message, 'warning', {});
                setIsLoading(false);
            });
    }

    const CustomToolBar = props => (
        <Toolbar className="mui-toolbar" {...props} >
        </Toolbar>
    );

    useEffect(() => {
        getClients();
    }, [])

    return (
        <div id="marketing" >
            <header>
                <h1>Enviar emails de marketing</h1>
            </header>
            <section className="clients">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <h2>Selecionar clientes</h2>
                    <FormControl style={{ minWidth: '200px'}}>
                        <InputLabel id="adv-filter-select-label">Filtros avançados</InputLabel>
                        <Select
                            labelId="adv-filter-select-label"
                            id="adv-filter-select"
                            onChange={(e) => {handleSelectAdvFilterChange(e)}}
                        >
                            <MenuItem value={"none"}>Não aplicado</MenuItem>
                            <MenuItem value={"hasPayments"}>Fez pagamentos</MenuItem>
                            <MenuItem value={"NOThasPayments"}>Não fez pagamentos</MenuItem>
                            <MenuItem value={"hasSocialSchedules"}>Comprou no social</MenuItem>
                            <MenuItem value={"hasGeneralSchedules"}>Comprou no geral</MenuItem>
                            <MenuItem value={"hasEvent"}>Comprou em evento</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ height: 500, width: "100%" }}>
                    <DataGrid
                        loading={isLoading}
                        checkboxSelection
                        onSelectionModelChange={(value) => {handleUsersSelection(value.selectionModel)} }
                        rows={clients}
                        columns={columns}
                        componentsProps={{
                            pagination: {
                               labelRowsPerPage: 'Linhas por página:' 
                            }
                        }}
                        localeText={gridTranslations}
                    />    
                </div>
            </section>
            <section className="emails" >
                <h2>Assunto</h2>
                <TextField
                    label="Assunto"
                    variant="outlined"
                    onChange={(event) => {setSubject(event.target.value)}}
                />
                <SimpleForm toolbar={<CustomToolBar/>}>
                    <TinyMCEInput source="email" label="Email" onChange={handleEmailMake} defaultEmail={defaultEmail} />
                </SimpleForm>
            </section>
            <div className="button-box">
                <Button disabled={!selectedClients || !mkEmail} onClick={() => {handleSubmitMarketingEmail()}}>Enviar emails</Button>
            </div>
        </div>
    )
}

export default Marketing;