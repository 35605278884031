import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

const CommentEdit = (props) => {

  const validateReq = required('Campo obrigatório');

  return (
    <Edit title="Editar Comentário" {...props}>
      <SimpleForm>
        <TextInput disabled source="id" label="ID" />
        <TextInput multiline validate={validateReq} source="content" label="Contéudo" />
      </SimpleForm>
    </Edit>
  );
};

export default CommentEdit;
