import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  DateField,
  BooleanField,
} from "react-admin";

const AdvertisementList = (props) => {

  return (
    <List title="Listar Anúncios" {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="Id" />
        <TextField source="name" label="Nome" />
        <DateField locales="fr-FR" source="expires_at" label="Validade" />
        <TextField source="clicks" label="Quantidade de clicks" />
        <TextField source="local" label="Local do anúncio" />
        <BooleanField source="active" label="Ativo/Inativo" />
        <DateField locales="fr-FR" source="createdAt" label="Publicado em"/>
        <EditButton basePath="/advertisements" />
        <DeleteButton basePath="/advertisements" />
      </Datagrid>
    </List>
  );
};

export default AdvertisementList;
