import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
  FunctionField
} from "react-admin";

const translateRole = (role) => {
  switch (role){
    case "client":
      return "Cliente";
    case "admin":
      return "Admin";
    case "professional":
      return "Profissional";
    case "company":
      return "Empresa";
    case "manager":
      return "Gerente";
    case "secretary":
      return "Secretário(a)";
  }
}

const PermissionList = (props) => {

  return (
    <List title="Listar Permissões" {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="Id" />
        <FunctionField label="Função" render={(record) => translateRole(record.role)} />
        <BooleanField source="create_users" label="Criar usuários" />
        <BooleanField source="create_services" label="Criar Terapias" />
        <BooleanField source="create_posts" label="Publicar no blog" />
        <EditButton basePath="/permissions" />
      </Datagrid>
    </List>
  );
};

export default PermissionList;
