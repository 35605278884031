import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  DateField,
  TextField,
  RichTextField,
  Datagrid,
  ImageField,
  ChipField,
  TabbedShowLayout,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
  BooleanField,
  ShowButton,
  ReferenceManyField,
  Tab,
  ReferenceField
} from "react-admin";

const PostShow = (props) => {

    const Title = ({ record }) => {
        return <span>Post {record ? `"${record.title}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <EditButton basePath={basePath} record={data} />
            <DeleteButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );

    return (
      <Show title={<Title />} actions={<ShowActions />} {...props}>
        <TabbedShowLayout>
          <Tab label="Infos">
            <TextField source="id" label="Id" />
            <TextField source="author_name" label="Autor"/>
            <TextField source="title" label="Título" />
            <TextField source="abstract" label="Resumo" />
            <ChipField source="category" label="Categoria" />
            <ChipField source="tags" label="Tags" />
            <BooleanField source="comentable" label="Permitir comentários" />
          </Tab>
          <Tab label="Texto" path="body">
            <TextField source="title" label="Título" />
            <ImageField source="thumbnail_url"label="Thumbnail" />
            <RichTextField
              source="body"
              stripTags={false}
              label=""
              addLabel={false}
            />
          </Tab>
          <Tab label="Comentários" path="comments">
            <ReferenceManyField
              addLabel={false}
              reference="comments"
              target="post"
              sort={{ field: 'createdAt', order: 'DESC' }}
            >
              <Datagrid>
                <DateField source="createdAt" label="Data" />
                <ReferenceField source="author" label="Usuário" reference="users">
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="content" label="comentário" />
                <ShowButton />
                <DeleteButton />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  };
  
  export default PostShow;
