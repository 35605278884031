import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Button, TextField } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import { pt } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useNotify, useRefresh } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';
import { Constants } from '../../../../constants';

import './styles.css';

const AddClientToSocialSchedule = () => {

    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();

    const [clients, setClients] = useState();
    const [services, setServices] = useState();
    const [professionals, setProfessionals] = useState();
    const [socials, setSocials] = useState();

    const [selectedClient, setSelectedClient] = useState();
    const [selectedService, setSelectedService] = useState();
    const [selectedProfessional, setSelectedProfessional] = useState();
    const [selectedSocial, setSelectedSocial] = useState();
    const [selectedDate, setSelectedDate] = useState();

    const getClients = async () => {

        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                range: "[0, 100000]",
                sort: `["name", "ASC"]`,
                filter: {}
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/users`, config)
            .then((response) => {

                setClients(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getServices = async () => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                day: new Date().getDate(),
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/socialSchedules/services`, config)
            .then((response) => {
                setServices(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getProfessionals = async (service) => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                day: new Date().getDate(),
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/socialSchedules/professionals/${service}`, config)
            .then((response) => {
                setProfessionals(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getSocials = async (date) => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                day: new Date(date).getDate(),
                month: new Date(date).getMonth() + 1,
                year: new Date(date).getFullYear(),
                service: selectedService
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/socialSchedules/professional/${selectedProfessional}`, config)
            .then((response) => {
                const data = response.data;
                const auxSocials = data.map(social => {
                    return {
                        id: social.id,
                        date: new Date(social.date).toLocaleString('pt-BR', {
                            dateStyle: 'short',
                        }),
                        hour: new Date(social.date).toLocaleString('pt-BR', {
                            timeStyle: 'short',
                            hour12: false,
                        }),
                        status: social.status,
                        type: social.type,
                    }
                });
                setSocials(auxSocials);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });

    }

    const handleSelectedService = (service) => {
        setSelectedService(service ? service.id : null);
        if(service){
            getProfessionals(service.id);
        }
    }

    const handleSelectedProfessional = (professional) => {
        setSelectedProfessional(professional.id);
    }

    const handleDateChange = (date) => {
        setSelectedDate(date)
        getSocials(date);
    }

    const handleSelectedSocial = (social) => {
        setSelectedSocial(social ? social.id : null);
    }

    const handleSubmitAddClientToSocial = async () => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {

            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.post(`${Constants.ApiUrl}/socialSchedules/admin/addclient`, {
            client: selectedClient.id,
            socialSchedulesIds: [selectedSocial]
        } ,config)
            .then((response) => {
                notify(response.data.message, 'info', {});
                // window.location.reload(); // perguntar pra cliente
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notify(err.response.data.message, 'warning', {});
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getClients();
        getServices();
    }, [])

    return (
        <div id="add-client" >
            <header>
                <h1>Agendar cliente no Social</h1>
            </header>
            <section>
                <h2>Selecionar cliente</h2>
                <Autocomplete
                    id="select-client"
                    options={clients}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    style={{width: 300}}
                    loading={isLoading}
                    disabled={isLoading}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Cliente"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {setSelectedClient(value)}}
                />
            </section>
            <section>
                <h2>Selecionar terapia</h2>
                <Autocomplete
                    id="select-service"
                    options={services}
                    getOptionLabel={(option) => option.name}
                    style={{width: 300}}
                    loading={isLoading}
                    disabled={isLoading}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Terapia"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedService(value)}}
                />
            </section>
            <section>
                <h2>Selecionar Profissional</h2>
                <Autocomplete
                    id="select-professional"
                    options={professionals}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    style={{width: 300}}
                    loading={isLoading}
                    disabled={isLoading || !professionals}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Profissional"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedProfessional(value)}}
                />
            </section>
            <section>
                <h2>Selecionar Dia</h2>
                <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Selecione o dia"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </section>
            <section>
                <h2>Selecionar Social</h2>
                <Autocomplete
                    id="select-social"
                    options={socials}
                    getOptionLabel={(option) => `${option.type} ${option.date} ${option.hour}`}
                    getOptionDisabled={(option) => option.status !== "Disponível"}
                    disabled={isLoading || !socials}
                    loading={isLoading}
                    style={{width: 300}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Social"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedSocial(value)}}
                />
            </section>
            <div className="button-box">
                <Button disabled={!selectedClient || !selectedSocial} onClick={() => {handleSubmitAddClientToSocial()}}>Confirmar</Button>
            </div>
        </div>
    )
}

export default AddClientToSocialSchedule