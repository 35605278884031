import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  DateField,
  BooleanField,
  NumberField,
  Filter,
  SearchInput,
  FunctionField
} from "react-admin";

const translateType = (type) => {
  switch (type){
    case "PERCENTAGE":
      return "Porcentagem(%)";
    case "VALUE":
      return "Valor($)";
    default:
      return "";
  }
}

const CouponList = (props) => {

  const ListFilter = props => (
    <Filter {...props}>
        <SearchInput source="code" alwaysOn />
    </Filter>
  );
  
  return (
    <List title="Listar Cupons promocionais" filters={<ListFilter/>} {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="ID" />
        <TextField source="code" label="Código" />
        <NumberField source="discount" label="Desconto"/>
        <FunctionField label="Tipo" render={(record) => translateType(record.discount_type)} />
        <NumberField source="reusable_rate" label="Utilização máxima"/>
        <NumberField source="use_rate" label="Utilizações"/>
        <DateField locales="pt-BR" source="valid_until" label="Válido Até" />
        <BooleanField source="available" label="Status"/>
        <EditButton basePath="/coupons" />
        <DeleteButton basePath="/coupons" />
      </Datagrid>
    </List>
  );
};

export default CouponList;
