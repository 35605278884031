import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Button, TextField } from '@material-ui/core';
import { useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';

import './styles.css';
import { Constants } from '../../../../constants';

const AddClientToEvent = () => {

    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();

    const [clients, setClients] = useState([]);
    const [events, setEvents] = useState([]);

    const [selectedClient, setSelectedClient] = useState();
    const [selectedEvent, setSelectedEvent] = useState();

    const getClients = async () => {

        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                range: "[0, 15000]",
                sort: `["name", "ASC"]`,
                filter: {}
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/users`, config)
            .then((response) => {
                setClients(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getEvents = async () => {
        setIsLoading(true);

        const config = {
            params: {
                range: "[0, 100]",
                sort: `["name", "ASC"]`,
                filter: {"status": "Disponível"}
            },
        }

        await Axios.get(`${Constants.ApiUrl}/events`, config)
            .then((response) => {

                const data = response.data;
                const auxEvents = data.map(event => {
                    return {
                        id: event.id,
                        date: new Date(event.date).toLocaleString('pt-BR', {
                            dateStyle: 'short',
                        }),
                        hour: new Date(event.date).toLocaleString('pt-BR', {
                            timeStyle: 'short',
                            hour12: false,
                        }),
                        name: event.name,
                        status: event.status,
                        type: event.type,
                    }
                });

                setEvents(auxEvents);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleSelectedEvent = (event) => {
        setSelectedEvent(event ? event.id : null);
    }

    const handleSubmitAddClientToEvent = async () => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {

            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.post(`${Constants.ApiUrl}/events/admin/addclient`, {
            client: selectedClient.id,
            eventId: selectedEvent
        } ,config)
            .then((response) => {
                notify(response.data.message, 'info', {});
                // window.location.reload(); // perguntar pra cliente
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notify(err.response.data.message, 'warning', {});
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getClients();
        getEvents();
    }, [])

    return (
        <div id="add-client-event" >
            <header>
                <h1>Incluir cliente em um evento</h1>
            </header>
            <section>
                <h2>Selecionar cliente</h2>
                <Autocomplete
                    id="select-client"
                    options={clients}
                    loading={isLoading}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    style={{width: 300}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Cliente"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {setSelectedClient(value)}}
                />
            </section>
            <section>
                <h2>Selecionar evento</h2>
                <Autocomplete
                    id="select-event"
                    options={events}
                    loading={isLoading}
                    getOptionLabel={(option) => `${option.name} ${option.date} ${option.hour} ${option.type}`}
                    style={{width: 300}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Evento"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedEvent(value)}}
                />
            </section>
            <div className="button-box">
                <Button disabled={!selectedClient || !selectedEvent} onClick={() => {handleSubmitAddClientToEvent()}}>Agendar cliente</Button>
            </div>
        </div>
    )
}

export default AddClientToEvent;