import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  ListButton,
  RefreshButton,
  NumberField,
  BooleanField,
  ReferenceField
} from "react-admin";

const SchedulesShow = (props) => {

    const Title = ({ record }) => {
        return <span>Agendamento {record ? `"${record.id}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID do Agendamento" />
        <ReferenceField source="client" label="Cliente" reference="users" link={false}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="professional" label="Profissional" reference="users" link={false}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="service" label="Terapia" reference="services" link={false}>
            <TextField source="name"/>
        </ReferenceField>
        <BooleanField source="client_attended" label="Cliente compareceu?" />
        <BooleanField source="is_gift" label="Foi presente?" />
        <TextField source="gifted_user_email" label="Email do cliente presenteado" />
        <TextField source="gifted_user_name" label="Nome do cliente presenteado" />
        <TextField source="type" label="Tipo" />
        <TextField source="status" label="Status do agendamento" />
        <NumberField source="price" label="Valor/Preço" />
        <ReferenceField source="used_plan" label="Plano utilizado" reference="plans" link={false}>
            <TextField source="name"/>
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export default SchedulesShow;
