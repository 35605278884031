import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
} from "react-admin";

const TagCreate = (props) => {

  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar"/>
    </Toolbar>
  );

  return (
    <Create title="Cadastrar nova TAG" {...props}>
      <SimpleForm redirect="list" toolbar={<TranslatedToolbar/>}>
        <TextInput source="name" label="Nome" />
      </SimpleForm>
    </Create>
  );
};

export default TagCreate;