import React, {useEffect, useState, useCallback} from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  DateInput,
  required,
  minValue,
  choices,
  minLength,
  regex,
  SelectArrayInput,
} from "react-admin";
import axios from 'axios';
import { Constants } from '../../constants';

const CouponEdit = (props) => {

  const [serviceChoices, setServiceChoices] = useState([]);
  const [professionalChoices, setProfessionalChoices] = useState([]);

  const getServices = useCallback( async () => {
    try{
        const response = await axios.get(Constants.ApiUrl + '/services', { params: { range: '[0,1000]', sort: `["id", "ASC"]` } });
        const auxServices = response.data;

        const transformedServices = auxServices.map( (service) => {
            return { id: service.id, name: `${service.name}` }
        })

        setServiceChoices(transformedServices);

    }catch(err){
        console.log(err);
    }         
  }, []);

  const getProfessionals = useCallback( async () => {
    try{
        const response = await axios.get(Constants.ApiUrl + '/users/professionals');
        
        const auxProfessionals = response.data;

        const transformedProfessionals = auxProfessionals.map((professional) => {
            return { id: professional.id, name: `${professional.name} ${professional.last_name}` };
        });

        setProfessionalChoices(transformedProfessionals);

    }catch(err){
        console.log(err);
    }
  }, []);

  useEffect(() => {
      getServices();
      getProfessionals();
  }, [getProfessionals,getServices])

  const validateReq = required('Campo obrigatório');
  const validateCode = [minLength(3), regex(/^[A-Z-0-9]*$/, 'Somente letras maiúsculas')]
  const validateDiscount = [required('Campo Obrigatório'), minValue(1, 'Não pode ser negativo')]
  const validateUses = [required('Campo Obrigatório'), minValue(1, 'Não pode ser negativo')];
  const validateCouponType = choices(['PERCENTAGE', 'VALUE'], 'Escolha o tipo');
  const validateCouponAvailability = choices([true, false], 'Escolha o status');
  
  const dateParser = v => {
    // v is a string of "YYYY-MM-DD" format
    const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    if (match === null) return;
    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3], 23, 59);
    if (isNaN(d)) return;
    return d;
  };

  return (
    <Edit title="Editar Cupom Promocional" {...props}>
      <SimpleForm>
        <TextInput validate={validateCode} source="code" label="Código" />
        <DateInput validate={validateReq} locales="pt-BR" source="valid_until" label="Válido Até" parse={dateParser}/>
        <SelectInput validate={validateCouponType} source="discount_type" label="Tipo de desconto" choices={[
          { id: 'PERCENTAGE', name: 'Porcentagem (%)' },
          { id: 'VALUE', name: 'Valor (R$)' }
        ]}/>
        <NumberInput validate={validateDiscount} source="discount" label="Desconto"/>
        <NumberInput validate={validateUses} source="reusable_rate" label="Limite de utilização"/>
        <SelectInput validate={validateCouponAvailability} source="available" label="Disponibilidade" choices={[
          { id: true, name: 'Disponível' },
          { id: false, name: 'Indisponível' },
        ]}/>
        <SelectArrayInput source="services" label="Terapias aplicadas" choices={serviceChoices}/>
        <SelectArrayInput source="professionals" label="Profissionais aplicados" choices={professionalChoices}/>
      </SimpleForm>
    </Edit>
  );
};

export default CouponEdit;
