const defaultEmail = `<body style="max-width: 720px; margin: 0 auto;">
<p style="font-size: 18px;">Olá <strong>Nome do cliente</strong>,</p>
<p style="width: 100%; color: red; text-align: center; font-weight: bold; font-size: 24px;">Header</p>
<p style="font-size: 18px;">Faltam menos de 24 horas para sua consulta!<br/><br/>
<div>
    <p>Conteúdo</p>
</div><br/>
Em caso de dúvidas, entre em contato.</p>
<div style="background: #ddd; padding: 10px; margin-bottom: 30px">
        <h4><a href="#" >Mundo Akar</a></h4>
        <p>Rua Felipe Nery, 1000, Patriolino Ribeiro, Fortaleza, CE - 60810-310 (85) 98165-1765 / (85) 3879-2444</p>
</div>
<div>
        <p>Baixe o aplicativo do <a href="https://zoom.us/" >ZOOM</a> gratuitamente para realizar os atendimentos online pelo smartphone, tablet ou computador de onde estiver!</p>
</div>
<span>Informações importantes:</span>
<footer style="background: #ddd; padding: 10px; margin-bottom: 30px; margin-top: 10px; font-size: 12px;">
        <ul>
                <li>Este é um e-mail automático, por favor, não responda.</li>
                <li>Observe as instruções especiais e as políticas de cancelamento presentes no site.</li>
        </ul>
</footer>
</body>`

export default defaultEmail;