import React, { useCallback, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Constants } from '../../../constants';
import axios from 'axios';

import './styles.css';

const AccessReports = () => {

    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [postChoices, setPostChoices] = useState([]);
    const [eventChoices, setEventChoices] = useState([]);
    const [page, setPage] = useState();
    const [selectedPost, setSelectedPost] = useState();
    const [selectedEvent, setSelectedEvent] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleInitialDateChange = (date) => {
      setSelectedInitialDate(date);
    };
    const handleFinalDateChange = (date) => {
        setSelectedFinalDate(date);
    };

    const getEvents = useCallback (async () => {
        try{
          const response = await axios.get(Constants.ApiUrl + '/events', { params: { range: '[0,200]' } });
    
          const auxEvents = response.data;
    
          const transformedEvents = auxEvents.map( (event) => {
              return { name: `${event.name}` }
          });

          transformedEvents.push({
            name: 'Todos',
        })
    
          setEventChoices(transformedEvents);
    
        }catch(err){
            console.log(err);
        }  
      }, []);

    const getPosts = useCallback (async () => {
        try{
          const response = await axios.get(Constants.ApiUrl + '/posts', { params: { range: '[0,200]' } });
    
          const auxPosts = response.data;
    
          const transformedPosts = auxPosts.map( (post) => {
              return { title: `${post.title}` }
          });

          transformedPosts.push({
              title: 'Todos',
          });
    
          setPostChoices(transformedPosts);
        }catch(err){
            console.log(err);
        }  
      }, []);

    const pageOptions = [
        {name: 'Todas'},
        {name: 'Home'},
        {name: 'Post do Blog'},
        {name: 'Trocar Senha'},
        {name: 'Contato'},
        {name: 'Evento'},
        {name: 'Pagamento Evento'},
        {name: 'Esqueci a senha'},
        {name: 'Selecionar Agendamentos'},
        {name: 'Pagamento Geral'},
        {name: 'Agenda Geral'},
        {name: 'Pagamento Social'},
        {name: 'Detalhes Plano'},
        {name: 'Home Planos'},
        {name: 'Pagamento Planos'},
        {name: 'Detalhes Profissionais'},
        {name: 'Perfil'},
        {name: 'Home Projetos'},
        {name: 'Agenda Pessoal'},
        {name: 'Avaliar agendamento'},
        {name: 'Detalhes Terapia'},
        {name: 'Home Salas'},
        {name: 'Home Espaços'},
        {name: 'Regras Social'},
        {name: 'Selecionar Agendamento Social'},
        {name: 'Galeria'},
        {name: 'Agenda Social'},
        {name: 'Espaços'},
    ]

    const handleChangePage = (value) => {
        setPage(value ? value.name : null);
    };

    const handleChangePost = (value) => {
        setSelectedPost(value ? value.title : 'Todos');
    }

    const handleChangeEvent = (value) => {
        setSelectedEvent(value ? value.name : 'Todos');
    }

    const hadleDownloadReport = async () => {
        const isoInitialDate = new Date(selectedInitialDate).toISOString();
        const isoFinalDate = new Date(selectedFinalDate).toISOString();

        const initialDateString = `${new Date(selectedInitialDate).getDate()}${(new Date(selectedInitialDate).getMonth()) + 1}${new Date(selectedInitialDate).getFullYear()}`;
        const finalDateString = `${new Date(selectedFinalDate).getDate()}${(new Date(selectedFinalDate).getMonth()) + 1}${new Date(selectedFinalDate).getFullYear()}`;

        const fileName = `relatorio-x-${initialDateString}-${finalDateString}.xlsx`;

        const user = JSON.parse(localStorage.getItem('auth'));
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await axios
            .get(`${Constants.ApiUrl}/reports/access?initial=${isoInitialDate}&final=${isoFinalDate}&page=${page}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                console.log(err);
            }))
    }

    const hadleDownloadPostsReport = async () => {
        const initialDateString = `${new Date().getDate()}${(new Date().getMonth()) + 1}${new Date().getFullYear()}`;

        const fileName = `relatorio-acesso-${initialDateString}.xlsx`;

        const user = JSON.parse(localStorage.getItem('auth'));
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await axios
            .get(`${Constants.ApiUrl}/reports/access/posts_access?postTitle=${selectedPost}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                console.log(err);
            }))
    }

    const hadleDownloadEventsReport = async () => {
        const initialDateString = `${new Date().getDate()}${(new Date().getMonth()) + 1}${new Date().getFullYear()}`;

        const fileName = `relatorio-acesso-${initialDateString}.xlsx`;

        const user = JSON.parse(localStorage.getItem('auth'));
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await axios
            .get(`${Constants.ApiUrl}/reports/access/events_access?eventName=${selectedEvent}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                console.log(err);
            }))
    }

    useEffect(() => {
        getPosts();
        getEvents();
    }, [getPosts, getEvents])

    return (
        <div id="relatorios" >
            <header>
                <h1>Relatórios</h1>
            </header>
            <section className="reports-area">
                <h2>Relatório de acesso às páginas</h2>
                <h3>Selecionar página</h3>
                <Autocomplete
                    id="select-event"
                    options={pageOptions}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionSelected={(option) => option.name}
                    style={{width: 300}}
                    loading={isLoading}
                    disabled={isLoading || !pageOptions}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Página"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleChangePage(value)}}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="pikers">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Data Inicial"
                                value={selectedInitialDate}
                                onChange={handleInitialDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className="date-piker"
                            />
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Data Final"
                                value={selectedFinalDate}
                                onChange={handleFinalDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className="date-piker"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CloudDownloadIcon/>}
                                onClick={hadleDownloadReport}
                                onLoad={isLoading}
                            >
                                Gerar Relatório
                            </Button>
                            {isLoading &&
                                <CircularProgress className="loading-spinner"/>
                            }
                    </div>
                </MuiPickersUtilsProvider>
            </section>
            <section className="reports-area">
                <h2>Relatório de accesso às postagens do Blog</h2>
                <h3>Selecionar Postagem</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Autocomplete
                        id="select-event"
                        options={postChoices}
                        getOptionLabel={(option) => `${option.title}`}
                        getOptionSelected={(option) => option.title}
                        style={{width: 300}}
                        loading={isLoading}
                        disabled={isLoading || !postChoices}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Postagem"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />}
                        onChange={(event, value) => {handleChangePost(value)}}
                    />
                    <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        color="primary"
                        startIcon={<CloudDownloadIcon/>}
                        onClick={hadleDownloadPostsReport}
                        onLoad={isLoading ? isLoading : false}
                    >
                        Gerar Relatório
                    </Button>
                    {isLoading &&
                        <CircularProgress className="loading-spinner"/>
                    }
                </div>
            </section>
            <section className="reports-area">
                <h2>Relatório de accesso aos eventos/projetos</h2>
                <h3>Selecionar Evento</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Autocomplete
                        id="select-event"
                        options={eventChoices}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionSelected={(option) => option.name}
                        style={{width: 300}}
                        loading={isLoading}
                        disabled={isLoading || !eventChoices}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Evento"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />}
                        onChange={(event, value) => {handleChangeEvent(value)}}
                    />
                    <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        color="primary"
                        startIcon={<CloudDownloadIcon/>}
                        onClick={hadleDownloadEventsReport}
                        onLoad={isLoading ? isLoading : false}
                    >
                        Gerar Relatório
                    </Button>
                    {isLoading &&
                        <CircularProgress className="loading-spinner"/>
                    }
                </div>
            </section>
        </div>
    )
}

export default AccessReports;