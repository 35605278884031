import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Constants } from '../../../constants';
import Axios from 'axios';

import './styles.css';

const CanceledSchedules = () => {
    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [selectedType, setSelectedType] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleInitialDateChange = (date) => {
        setSelectedInitialDate(date);
    };
    const handleFinalDateChange = (date) => {
          setSelectedFinalDate(date);
    };
  
    const hadleDownloadReport = async () => {
          const isoInitialDate = new Date(selectedInitialDate).toISOString();
          const isoFinalDate = new Date(selectedFinalDate).toISOString();
  
          const initialDateString = `${new Date(selectedInitialDate).getDate()}${(new Date(selectedInitialDate).getMonth()) + 1}${new Date(selectedInitialDate).getFullYear()}`;
          const finalDateString = `${new Date(selectedFinalDate).getDate()}${(new Date(selectedFinalDate).getMonth()) + 1}${new Date(selectedFinalDate).getFullYear()}`;
  
          const fileName = `relatorio-x-${initialDateString}-${finalDateString}.xlsx`;
  
          const user = JSON.parse(localStorage.getItem('auth'));
          const config = {
              headers: {
                  'Authorization': `Bearer ${user.token}`
              },
              responseType:'blob'
          }
  
          setIsLoading(true);
  
          await Axios
              .get(`${Constants.ApiUrl}/reports/canceled-schedules?initial=${isoInitialDate}&final=${isoFinalDate}&type=${selectedType}`, config)
              .then((response) => {
                  setIsLoading(false);
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', fileName);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();// you need to remove that elelment which is created before.
              })
              .catch((err => {
                  console.log(err);
              }))
    }
  
    const handleSelectedType = (type) => {
          setSelectedType(type ? type.type : null);
    }

    const schedulesTypes = [
        {type: 'general', name: 'Agendamentos Gerais' },
        {type: 'social', name: 'Agendamentos Sociais' },
        {type: 'events', name: 'Eventos' },
    ]

    return (
        <div id="relatorios" >
            <header>
                <h1>Relatórios</h1>
            </header>
            <section className="reports-area">
                <h2>Agendamentos Cancelados</h2>
                <h2>Selecionar Tipo</h2>
                <Autocomplete
                    id="select-type"
                    options={schedulesTypes}
                    getOptionLabel={(option) => `${option.name}`}
                    style={{width: 300}}
                    loading={isLoading}
                    disabled={isLoading || !schedulesTypes}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Tipo"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedType(value)}}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="pikers">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data Inicial"
                                    value={selectedInitialDate}
                                    onChange={handleInitialDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="date-piker"
                                />
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data Final"
                                    value={selectedFinalDate}
                                    onChange={handleFinalDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="date-piker"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudDownloadIcon/>}
                                    onClick={hadleDownloadReport}
                                    onLoad={isLoading}
                                >
                                    Gerar Relatório
                                </Button>
                                {isLoading &&
                                    <CircularProgress className="loading-spinner"/>
                                }
                        </div>
                    </MuiPickersUtilsProvider>
            </section>
        </div>
    )
}

export default CanceledSchedules;