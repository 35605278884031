import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  ReferenceField,
  ImageField,
  ChipField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
  BooleanField
} from "react-admin";

const SpaceShow = (props) => {

    const Title = ({ record }) => {
        return <span>Espaço {record ? `"${record.name}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <EditButton basePath={basePath} record={data} />
            <DeleteButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );

    return (
      <Show title={<Title />} actions={<ShowActions />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="Id" />
          <TextField source="name" label="Nome do espaço" />
          <ImageField source="thumbnail_url"label="Thumbnail" />
          <RichTextField source="description" label="Descrição/Detalhes" />
          <ChipField source="category" label="Categoria" />
        </SimpleShowLayout>
      </Show>
    );
  };
  
  export default SpaceShow;
  