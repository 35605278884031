import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const ManualOperations = () => {

    const history = useHistory();

    return (
        <div id="manual-operations" >
            <header>
                <h1>Operações Manuais</h1>
            </header>
            <section>
                <h2>Agendamentos socials</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {history.push('/manual-operations/social-schedules/add-client')}}
                    >
                        Agendar cliente
                    </Button>
                </div>
            </section>
            <section>
                <h2>Agendamentos gerais</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {history.push('/manual-operations/general-schedules/add-client')}}
                    >
                        Agendar cliente
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {history.push('/manual-operations/general-schedules/remove-client')}}
                    >
                        Cancelar consulta</Button>
                </div>
            </section>
            <section>
                <h2>Planos</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {history.push('/manual-operations/plans/add-client')}}
                    >
                        Incluir cliente
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {history.push('/manual-operations/plans/remove-client')}}
                    >
                        Remover cliente
                    </Button>
                </div>
            </section>
            <section>
                <h2>Eventos</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {history.push('/manual-operations/events/add-client')}}
                    >
                        Agendar/Incluir cliente
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {history.push('/manual-operations/events/remove-client')}}
                    >
                        Cancelar/Remover cliente
                    </Button>
                </div>
            </section>
        </div>
    )

}

export default ManualOperations;