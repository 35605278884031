import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton
} from "react-admin";

const GaleryList = (props) => {
  
  return (
    <List title="Galeria" {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="ID" />
        <TextField source="main_text" label="Texto Principal" />
        <EditButton basePath="/galery" />
      </Datagrid>
    </List>
  );
};

export default GaleryList;
