import React from 'react';
import {
  Datagrid,
  DateField,
  DeleteButton,
  List,
  ReferenceField,
  Responsive,
  ShowButton,
  SimpleList,
  TextField
} from 'react-admin';

const CommentList = props => (
  <List {...props}>
    <Responsive
      small={
        <SimpleList
          linkType="show"
          primaryText={record => record.content}
          tertiaryText={record =>
            new Date(record.createdAt).toLocaleDateString()
          }
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" label="ID" />
          <DateField source="createdAt" label="Data" />
          <TextField source="content" label="Comentário" />
          <ReferenceField
            resource="comments"
            source="author"
            reference="users"
            label="Usuário"
          >
            <TextField source="name" label="Usuário" />
          </ReferenceField>
          <ReferenceField
            resource="comments"
            source="post"
            reference="posts"
            label="Postagem"
          >
            <TextField source="title" />
          </ReferenceField>
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default CommentList;
