import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, TextField } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab'
import { gridTranslations } from '../../custom/gridTranslations';

import { Constants } from '../../../constants';
import axios from 'axios';

import './styles.css';

const EventReports = () => {

    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [eventClients, setEventClients] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        { field : 'name', headerName: 'Nome', width: 160 },
        { field : 'last_name', headerName: 'Sobre Nome', width: 180 },
        { field : 'email', headerName: 'Email', width: 250 },
        { field : 'role', headerName: 'Função', width: 110, hide: true },
        { field : 'state', headerName: 'UF', width: 80, hide: true },
        { field : 'city', headerName: 'Cidade', hide: true },
        { field : 'zip_code', headerName: 'CEP', width: 160, hide: true },
        { field : 'phone', headerName: 'Telefone', hide: true, width: 180 },
        { field : 'cpf_cnpj', headerName: 'CPF/CNPJ', hide: true, width: 250 },
        { field : 'company_name', headerName: 'Nome da Empresa', hide: true },
        { field : 'employee_quantity', headerName: 'Funcionários', hide: true, type: 'number' },
        { field : 'active', headerName: 'Ativo', hide: true, type: 'boolean' },
        { field : 'notify', headerName: 'Notificações', hide: true, type: 'boolean' },
        { field : 'comission', headerName: 'Comissão', hide: true, type: 'number' },
        { field : 'createdAt', headerName: 'Criação', width: 150, hide: true, type: 'dateTime' },
    ];

    const handleInitialDateChange = (date) => {
      setSelectedInitialDate(date);
    };
    const handleFinalDateChange = (date) => {
        setSelectedFinalDate(date);
    };

    const getEvents = async () => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                // filter: {
                //     "role": "professional"
                // }
                range: '[0,50]',
                sort: `["createdAt", "DESC"]`,
                include: `["eventClient"]`,
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await axios.get(`${Constants.ApiUrl}/events`, config)
            .then((response) => {

                const data = response.data;
                const auxEvents = data.map(event => {
                    return {
                        id: event.id,
                        date: new Date(event.date).toLocaleString('pt-BR', {
                            dateStyle: 'short',
                        }),
                        hour: new Date(event.date).toLocaleString('pt-BR', {
                            timeStyle: 'short',
                            hour12: false,
                        }),
                        name: event.name,
                        status: event.status,
                        type: event.type,
                        clients: event.eventClient,
                        originalFields: event
                    }
                });

                const genericEvent = {
                    id: 'Todos',
                    name: 'Todos',
                }

                auxEvents.push(genericEvent);

                setEvents(auxEvents);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const hadleDownloadReport = async () => {
        const isoInitialDate = new Date(selectedInitialDate).toISOString();
        const isoFinalDate = new Date(selectedFinalDate).toISOString();

        const initialDateString = `${new Date(selectedInitialDate).getDate()}${(new Date(selectedInitialDate).getMonth()) + 1}${new Date(selectedInitialDate).getFullYear()}`;
        const finalDateString = `${new Date(selectedFinalDate).getDate()}${(new Date(selectedFinalDate).getMonth()) + 1}${new Date(selectedFinalDate).getFullYear()}`;

        const fileName = `relatorio-x-${initialDateString}-${finalDateString}.xlsx`;

        const user = JSON.parse(localStorage.getItem('auth'));
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await axios
            .get(`${Constants.ApiUrl}/reports/events/general?initial=${isoInitialDate}&final=${isoFinalDate}&event=${selectedEvent.id}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                console.log(err);
            }))
    }

    const handleSelectedEvent = (event) => {
        setSelectedEvent(event);
        setEventClients(event.clients);
    }

    useEffect(() => {
        getEvents();
    }, [])

    return (
        <div id="relatorios" >
            <header>
                <h1>Relatórios</h1>
            </header>
            <section className="reports-area">
                <h2>Eventos</h2>
                <h2>Selecionar evento</h2>
                <Autocomplete
                    id="select-event"
                    options={events}
                    getOptionLabel={(option) => `${option.name} ${option.date ? option.date : ''} ${option.hour ? option.hour : ''} ${option.type ? option.type : ''}`}
                    style={{width: 300}}
                    loading={isLoading}
                    disabled={isLoading || !events}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Evento"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedEvent(value)}}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="pikers">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Data Inicial"
                                value={selectedInitialDate}
                                onChange={handleInitialDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className="date-piker"
                            />
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Data Final"
                                value={selectedFinalDate}
                                onChange={handleFinalDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className="date-piker"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CloudDownloadIcon/>}
                                onClick={hadleDownloadReport}
                                onLoad={isLoading}
                            >
                                Gerar Relatório
                            </Button>
                            {isLoading &&
                                <CircularProgress className="loading-spinner"/>
                            }
                    </div>
                </MuiPickersUtilsProvider>
                {
                    (selectedEvent && selectedEvent.id !== 'Todos')
                        &&
                        <div className="event-details">
                            <h1>{selectedEvent?.originalFields.name}</h1>
                            <h3>Detalhes do evento:</h3>
                            <p>Data: {new Date(selectedEvent?.originalFields.date).toLocaleDateString('pt-br')}</p>
                            <p>Encerramento: {new Date(selectedEvent?.originalFields.conclusion).toLocaleDateString('pt-br')}</p>
                            <p>Preço: {selectedEvent?.originalFields.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            <p>Vagas preenchidas: {`${selectedEvent?.originalFields.scheduled_clients} de ${selectedEvent?.originalFields.max_clients}`}</p>
                            <p>Tipo: {selectedEvent?.originalFields.type}</p>
                        </div>
                }
                {
                    eventClients && eventClients.length > 0
                    ? (
                        <div style={{ height: 500, width: "100%", marginTop: 20 }}>
                            <h3>Clientes agendados:</h3>
                            <DataGrid 
                                componentsProps={{
                                    pagination: {
                                       labelRowsPerPage: 'Linhas por página:' 
                                    }
                                }}
                                localeText={gridTranslations}
                                rows={eventClients}
                                columns={columns}
                            />
                        </div>
                    )
                    : (selectedEvent && selectedEvent.id !== 'Todos' ) && <span style={{ marginTop: 20 }}>Nenhum cliente se increveu neste evento ainda.</span>
                }
            </section>
        </div>
    )
}

export default EventReports;