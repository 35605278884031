import React from "react";
import CardActions from "@material-ui/core/CardActions";

import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  ListButton,
  RefreshButton,
  ReferenceField,
  UrlField,
  FunctionField,
} from "react-admin";

const InvoicesShow = (props) => {
  const translateStatus = (status) => {
    switch (status) {
      case "SCHEDULED":
        return "AGENDADA";
      case "SYNCHRONIZED":
        return "ENVIADA";
      case "AUTHORIZED":
        return "EMITIDA";
      case "CANCELLED":
        return "CANCELADA";
      case "ERROR":
        return "ERRO";
      default:
        return "";
    }
  };
  const Title = ({ record }) => {
    return <span>Nota {record ? `"${record.rpsNumber}"` : ""}</span>;
  };

  const ShowActions = ({ basePath, data, resource }) => (
    <CardActions>
      <ListButton basePath={basePath} record={data} />
      <RefreshButton basePath={basePath} record={data} />
    </CardActions>
  );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <FunctionField
          label="Status"
          render={(record) => translateStatus(record.status)}
        />
        <TextField source="type" label="Tipo" />
        <TextField source="rpsSerie" label="Série" />
        <TextField source="rpsNumber" label="Número" />
        <TextField source="number" label="Número" />
        <NumberField source="value" label="Valor" />
        <NumberField source="effectiveDate" label="Data de Emissão" />
        <TextField source="customer" label="ID do cliente na Asaas" />
        <UrlField source="pdfUrl" label="Baixar PDF da nota" />
        <UrlField source="xmlUrl" label="Baixar xml da nota" />
        <ReferenceField reference="payments" source="payment" label="Pagamento">
          <TextField source="id" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export default InvoicesShow;
