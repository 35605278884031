import React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    DeleteButton,
    DateField,
    ChipField,
} from "react-admin";

const EventList = (props) => {

  return (
    <List title="Listar Eventos" {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="name" label="Nome" />
        <TextField source="organizer" label="Organizador" />
        <ChipField source="category" label="Categoria" />
        <TextField source="status" label="Status" />
        <NumberField source="max_clients" label="Maximo de clientes" />
        <NumberField source="scheduled_clients" label="Clientes agendados" />
        <DateField locales="pt-BR" showTime source="date" label="Data" />
        <TextField source="type" label="Tipo" />
        <EditButton basePath="/events" />
        <DeleteButton basePath="/events" />
      </Datagrid>
    </List>
  );
};

export default EventList;
