import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from "react-admin";

const TagList = (props) => {

  return (
    <List title="Listar Tags" {...props}>
      <Datagrid>
        <TextField source="name" label="Nome" />
        <EditButton basePath="/tags" />
        <DeleteButton basePath="/tags" />
      </Datagrid>
    </List>
  );
};

export default TagList;
