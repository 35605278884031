import React, { useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  useInput,
  required,
} from "react-admin";
import TinyMCEInput from "../helpers/TinyMCEInput";
import { Rooms } from "./Rooms";
import { useRooms } from "./useRooms";

const Form = () => {
  const validateReq = required("Campo obrigatório");
  const { input } = useInput({ source: "rooms" });
  const roomsProps = useRooms();
  const { selectedRooms } = roomsProps;

  useEffect(() => {
    input.onChange(selectedRooms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRooms]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextInput validate={validateReq} source="name" label="Nome" />
      <TinyMCEInput
        validate={validateReq}
        source="description"
        label="Descrição"
      />
      <TextInput source="weight" label="Ordem do cabeçalho" defaultValue={0} type="number" />
      <ImageInput
        source="main_image"
        label="Imagem principal"
        accept="image/*"
        validate={validateReq}
        placeholder={
          <p>Arraste e solte ou clique para inserir uma nova imagem</p>
        }
      >
        <ImageField source="main_image" title="Imagem principal" />
      </ImageInput>
      <Rooms {...roomsProps} />
    </div>
  );
};

const PageCreate = (props) => {
  const TranslatedToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton label="Salvar" />
    </Toolbar>
  );

  return (
    <Create title="Criar página" {...props}>
      <SimpleForm redirect="list" toolbar={<TranslatedToolbar />}>
        <Form />
      </SimpleForm>
    </Create>
  );
};

export default PageCreate;
