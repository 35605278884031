import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
  NumberField,
  ChipField,
  SingleFieldList,
  ReferenceManyField,
  ImageField,
  DateField
} from "react-admin";

const PlanShow = (props) => {

    const Title = ({ record }) => {
        return <span>Plano {record ? `"${record.name}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <EditButton basePath={basePath} record={data} />
            <DeleteButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );
  
    return (
      <Show title={<Title />} actions={<ShowActions />} {...props}>
        <SimpleShowLayout rowClick={'edit'}>
            <TextField  source="name" label="Nome"/>
            <ImageField source="image" label="Thumbnail" />
            <TextField  source="description" label="Descrição"/>
            <NumberField source="quantity" label="Quantidade de consultas"/>
            <NumberField source="price" lavel="Preço"/>
            <ReferenceManyField reference="services" target="id" source="services" label="Terapias">
              <SingleFieldList>
                <ChipField source="name"/>
              </SingleFieldList>
            </ReferenceManyField>
            <ReferenceManyField reference="users" target="id" source="professionals" label="Profissionais">
              <SingleFieldList>
                <ChipField source="name"/>
              </SingleFieldList>
            </ReferenceManyField>
            <DateField locales="pt-BR" source="valid_until" label="Válido Até" />
            <DateField locales="pt-BR" source="sold_until" label="Vendido Até" />
            <TextField source="status" label="Status" />
        </SimpleShowLayout>
      </Show>
    );
  };
  
  export default PlanShow;
  