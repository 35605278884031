import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Button, TextField } from '@material-ui/core';
import { useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';
import { Constants } from '../../../../constants';

import './styles.css';

const RemoveClientFromGeneralSchedule = () => {

    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();

    const [clients, setClients] = useState([]);
    const [schedules, setSchedules] = useState([]);

    const [selectedClient, setSelectedClient] = useState({});
    const [selectedSchedule, setSelectedSchedule] = useState({});
    const [reason, setReason] = useState();
    const [cancelBy, setCancelBy] = useState();

    const getClients = async () => {

        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                range: "[0, 15000]",
                sort: `["name", "ASC"]`,
                filter: {}
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/users`, config)
            .then((response) => {

                setClients(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getSchedules = async (client) => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/schedules/client/${client.id}`, config)
            .then((response) => {
                const data = response.data;
                const auxSchedules = data.map((schedule) => {
                    return {
                        id: schedule.id,
                        isoDate: schedule.date,
                        date: new Date(schedule.date).toLocaleString('pt-BR', {
                            dateStyle: 'short',
                        }),
                        hour: new Date(schedule.date).toLocaleString('pt-BR', {
                            timeStyle: 'short',
                            hour12: false,
                        }),
                        status: schedule.status,
                        type: schedule.type,
                    }
                });
                
                setSchedules(auxSchedules);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });

    }

    const handleSelectedClient = (client) => {
        setSelectedClient(client ? client : null);
        if(client){
            getSchedules(client);
        }
    }

    const handleSelectedSchedule = (schedule) => {
        setSelectedSchedule(schedule ? schedule.id : null);
    }

    const handleSubmitRemoveSchedule = async () => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {

            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        await Axios.post(`${Constants.ApiUrl}/schedules/admin/removeclient`, {
            clientId: selectedClient.id,
            scheduleId: selectedSchedule,
            reason: reason,
            cancelBy: cancelBy
        },config)
            .then((response) => {
                setIsLoading(false);
                notify(response.data.message, 'info', {});
            })
            .catch((err) => {
                console.log(err);
                notify(err.response.data.message, 'warning', {});
                setIsLoading(false);
            });
        setIsLoading(false);
    }

    useEffect(() => {
        getClients();
    }, [])

    return (
        <div id="remove-client-from-general-schedule" >
            <header>
                <h1>Cancelar agendamento geral de um cliente</h1>
            </header>
            <section>
                <h2>Selecionar cliente</h2>
                <Autocomplete
                    id="select-client"
                    options={clients}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    style={{width: 300}}
                    loading={isLoading}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Cliente"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedClient(value)}}
                />
            </section>
            <section>
                <h2>Selecionar agendamento</h2>
                <Autocomplete
                    id="select-schedule"
                    options={schedules}
                    getOptionLabel={(option) => `${option.type} ${option.date} ${option.hour}`}
                    getOptionDisabled={(option) => new Date(option.isoDate) < new Date() }
                    style={{width: 300}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Horário"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedSchedule(value)}}
                />
            </section>
            <section>
                <h2>Motivo do cancelamento</h2>
                <TextField
                    label="Motivo"
                    variant="outlined"
                    onChange={(event) => {setReason(event.target.value)}}
                />
            </section>
            <section>
                <h2>Quem está cancelando</h2>
                <TextField
                    label="Cencelado por..."
                    variant="outlined"
                    onChange={(event) => {setCancelBy(event.target.value)}}
                />
            </section>
            <div className="button-box">
                <Button disabled={!selectedClient || !selectedSchedule} onClick={() => {handleSubmitRemoveSchedule()}}>Cancelar consulta</Button>
            </div>
        </div>
    )

}

export default RemoveClientFromGeneralSchedule;