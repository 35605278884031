import CardActions from '@material-ui/core/CardActions';
import {
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
  BooleanField,
  UrlField,
  FunctionField,
} from "react-admin";

const translateRole = (role) => {
  switch (role){
    case "client":
      return "Cliente";
    case "admin":
      return "Admin";
    case "professional":
      return "Profissional";
    case "company":
      return "Empresa";
    case "manager":
      return "Gerente";
    case "secretary":
      return "Secretário(a)";
    default:
      return "Função";
  }
}

const UserShow = (props) => {

  const Title = ({ record }) => {
      return <span>Usuário {record ? `"${record.name}"` : ''}</span>;
  };

  const ShowActions = ({ basePath, data, resource }) => (
      <CardActions>
          <ListButton basePath={basePath} record={data} />
          <EditButton basePath={basePath} record={data} />
          <DeleteButton basePath={basePath} record={data} />
          <RefreshButton basePath={basePath} record={data} />
      </CardActions>
  );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID de usuário" />
        <TextField source="name" label="Nome" />
        <TextField source="last_name" label="Sobrenome" />
        <FunctionField
          label="Nascimento"
          render={(record) => {
            if(record.birth === ''){
              return (
                <span>Não informada</span>
              )
            }
            return (
              <span>{new Date(record.birth).toLocaleDateString('pt-br')}</span>
            )
          }}
        />
        <EmailField source="email" label="Email" />
        <TextField source="phone" label="Telefone" />
        <TextField source="cpf_cnpj" label="CPF" />
        <TextField source="zip_code" label="CEP" />
        <TextField source="street" label="Rua" />
        <TextField source="street_number" label="Número" />
        <TextField source="complement" label="Complemento" />
        <TextField source="neighborhood" label="Bairro" />
        <TextField source="city" label="Cidade" />
        <TextField source="state" label="Estado" />
        <TextField source="specialties" label="Especialidade(s)"/>
        <TextField source="crp_crm_trt" label="CRP/CRM/TRT"/>
        <TextField source="mini_curriculum" label="Mini Currículo"/>
        <UrlField source="curriculum" label="Currículo (pdf)" />
        <UrlField source="diplomas" label="Diplomas (zip)" />
        <TextField source="company_name" label="Nome Fantasia"/>
        <TextField source="company_field" label="Área da empresa"/>
        <TextField source="employee_quantity" label="Quantidade de funcionários"/>
        <TextField source="business_contact" label="Contato comercial"/>
        <TextField source="financial_contact" label="Contato financeiro"/>
        <TextField source="asaas_api_key" label="API Key Asaas" />
        <TextField source="zoom_email" label="Email Zoom" />
        <TextField source="zoom_api_key" label="API Key Zoom" />
        <TextField source="zoom_api_secret" label="API Secret" />
        <FunctionField label="Função" render={(record) => translateRole(record.role)} />
        <BooleanField source="active" label="Ativo"/>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
