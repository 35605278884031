import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  DeleteButton,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  DateField
} from "react-admin";

const PlanList = (props) => {
  
  return (
    <List title="Lista de planos" {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome"/>
        <TextField source="description" label="Descrição" />
        <NumberField source="quantity" label="Consultas" />
        <NumberField source="price" label="Preço" options={{ style: 'currency', currency: 'BRL' }} />
        <ReferenceManyField reference="services" target="id" source="services" label="Terapias">
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceManyField>
        <DateField locales="pt-BR" source="valid_until" label="Válido Até" />
        <TextField source="status" label="Status"/>
        <EditButton basePath="/plans" />
        <DeleteButton basePath="/plans" />
      </Datagrid>
    </List>
  );
};

export default PlanList;
