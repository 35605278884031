import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
  UrlField
} from "react-admin";

const SocialScheduleShow = (props) => {

    const Title = ({ record }) => {
        return <span>Agendamento Social {record ? `"${record.id}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <EditButton basePath={basePath} record={data} />
            <DeleteButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );

    return (
      <Show title={<Title />} actions={<ShowActions />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="ID do Agendamento" />
          <TextField source="type" label="Presencial ou Online" />
          <DateField locales="pt-BR" showTime source="date" label="Data" />
          <NumberField source="duration" label="Duração (minutos)" />
          <DateField locales="pt-BR" showTime source="sold_until" label="Limite de horário para venda" />
          <ReferenceField source="service" label="Serviço" reference="services">
            <TextField source="name"/>
          </ReferenceField>
          <ReferenceField source="professional" label="Profissional" reference="users">
            <TextField source="name"/>
          </ReferenceField>
          <TextField source="status" label="Status" />
          <TextField source="client_type" label="Individual ou Grupo" />
          <NumberField source="max_clients" label="Maximo de clientes" />
          <NumberField source="scheduled_clients" label="Clientes agendados" />
          <NumberField source="price" label="Preço" />
          <UrlField source="zoom_url" label="Url para sala do Zoom"/>
        </SimpleShowLayout>
      </Show>
    );
  };
  
  export default SocialScheduleShow;
  