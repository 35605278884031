import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Constants } from '../../../../constants';
import Axios from 'axios';

import './styles.css';

const FreeEventsReports = () => {

    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [events, setEvents] = useState();
    const [selectedEvent, setSelectedEvent] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleInitialDateChange = (date) => {
      setSelectedInitialDate(date);
    };
    const handleFinalDateChange = (date) => {
        setSelectedFinalDate(date);
    };

    const getEvents = async () => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/events`, config)
            .then((response) => {

                const data = response.data;
                const auxEvents = data.map(event => {
                    return {
                        id: event.id,
                        date: new Date(event.date).toLocaleString('pt-BR', {
                            dateStyle: 'short',
                        }),
                        hour: new Date(event.date).toLocaleString('pt-BR', {
                            timeStyle: 'short',
                            hour12: false,
                        }),
                        name: event.name,
                        status: event.status,
                        type: event.type,
                    }
                });

                const genericEvent = {
                    id: 'Todos',
                    name: 'Todos',
                }

                auxEvents.push(genericEvent);

                setEvents(auxEvents);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const hadleDownloadReport = async () => {
        const isoInitialDate = new Date(selectedInitialDate).toISOString();
        const isoFinalDate = new Date(selectedFinalDate).toISOString();

        const initialDateString = `${new Date(selectedInitialDate).getDate()}${(new Date(selectedInitialDate).getMonth()) + 1}${new Date(selectedInitialDate).getFullYear()}`;
        const finalDateString = `${new Date(selectedFinalDate).getDate()}${(new Date(selectedFinalDate).getMonth()) + 1}${new Date(selectedFinalDate).getFullYear()}`;

        const fileName = `relatorio-x-${initialDateString}-${finalDateString}.xlsx`;

        const user = JSON.parse(localStorage.getItem('auth'));
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await Axios
            .get(`${Constants.ApiUrl}/reports/events/free?initial=${isoInitialDate}&final=${isoFinalDate}&event=${selectedEvent}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                console.log(err);
            }))
    }

    const handleSelectedEvent = (event) => {
        setSelectedEvent(event ? event.id : null);
    }

    useEffect(() => {
        getEvents();
    }, [])

    return (
        <div id="relatorios" >
            <header>
                <h1>Relatórios</h1>
            </header>
            <section className="reports-area">
                <h2>Visualizar ingressos gratuitos nos eventos</h2>
                <h2>Selecionar Evento</h2>
                <Autocomplete
                    id="select-event"
                    options={events}
                    getOptionLabel={(option) => `${option.name} ${option.date ? option.date : ''} ${option.hour ? option.hour : ''} ${option.type ? option.type : ''}`}
                    style={{width: 300}}
                    loading={isLoading}
                    disabled={isLoading || !events}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Evento"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedEvent(value)}}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="pikers">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data Inicial"
                                    value={selectedInitialDate}
                                    onChange={handleInitialDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="date-piker"
                                />
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data Final"
                                    value={selectedFinalDate}
                                    onChange={handleFinalDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="date-piker"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudDownloadIcon/>}
                                    onClick={hadleDownloadReport}
                                    onLoad={isLoading}
                                >
                                    Gerar Relatório
                                </Button>
                                {isLoading &&
                                    <CircularProgress className="loading-spinner"/>
                                }
                        </div>
                    </MuiPickersUtilsProvider>
            </section>
        </div>
    )
}

export default FreeEventsReports;