import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  required,
  Toolbar,
  SaveButton,
} from "react-admin";

const GalleryCreate = (props) => {
  const TranslatedToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton label="Salvar" />
    </Toolbar>
  );

  const validateReq = required("Campo obrigatório");

  return (
    <Create title="Nova galeria" {...props}>
      <SimpleForm redirect="list" toolbar={<TranslatedToolbar />}>
        <TextInput
          multiline
          source="main_text"
          validate={validateReq}
          label="Texto principal"
        />
        <TextInput
          source="main_video_url"
          validate={validateReq}
          label="URL do video principal"
        />
        <TextInput source="video2_url" label="URL do video 2" />
        <TextInput source="video3_url" label="URL do video 3" />
        <TextInput source="video4_url" label="URL do video 4" />
        <TextInput source="video5_url" label="URL do video 5" />
        <ImageInput
          source="image1"
          label="Nova Imagem 1"
          accept="image/*"
          placeholder={
            <p> Arraste e solte ou clique para inserir uma nova imagem </p>
          }
        >
          <ImageField source="image1" title="Nova Imagem 1" />
        </ImageInput>
        <ImageInput
          source="image2"
          label="Nova Imagem 2"
          accept="image/*"
          placeholder={
            <p> Arraste e solte ou clique para inserir uma nova imagem </p>
          }
        >
          <ImageField source="image2" title="Nova Imagem 2" />
        </ImageInput>
        <ImageInput
          source="image3"
          label="Nova Imagem 3"
          accept="image/*"
          placeholder={
            <p> Arraste e solte ou clique para inserir uma nova imagem </p>
          }
        >
          <ImageField source="image3" title="Nova Imagem 3" />
        </ImageInput>
        <ImageInput
          source="image4"
          label="Nova Imagem 4"
          accept="image/*"
          placeholder={
            <p> Arraste e solte ou clique para inserir uma nova imagem </p>
          }
        >
          <ImageField source="image4" title="Nova Imagem 4" />
        </ImageInput>
        <ImageInput
          source="image5"
          label="Nova Imagem 5"
          accept="image/*"
          placeholder={
            <p> Arraste e solte ou clique para inserir uma nova imagem </p>
          }
        >
          <ImageField source="image5" title="Nova Imagem 5" />
        </ImageInput>
        <ImageInput
          source="image6"
          label="Nova Imagem 6"
          accept="image/*"
          placeholder={
            <p> Arraste e solte ou clique para inserir uma nova imagem </p>
          }
        >
          <ImageField source="image6" title="Nova Imagem 6" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default GalleryCreate;
