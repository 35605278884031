import React, {useState, useCallback, useEffect} from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  SelectInput,
  ImageField,
  FormDataConsumer,
  SelectArrayInput,
  Labeled,
  required,
  choices,
} from "react-admin";
import axios from 'axios';
import { Constants } from '../../constants';

const ServiceEdit = (props) => {

  const [tagChoices, setTagChoices] = useState([]);

  const getTags =  useCallback( async () => {
    try{
        const response = await axios.get(Constants.ApiUrl + '/tags', { params: { range: '[0,1000]' } });

        console.log(response.data);
        const auxServices = response.data;

        const transformedTags = auxServices.map( (tag) => {
            return { id: tag.name, name: `${tag.name}` }
        })

        setTagChoices(transformedTags);

    }catch(err){
        console.log(err);
    }         
  }, []);

  useEffect(() => {
      getTags();
  }, [getTags])

  const validateReq = required('Campo obrigatório');
  const validateStatus = [required('Campo obrigatório'), choices(['Ativo', 'Inativo'], 'Escolha o status da terapia')]
  const validateType = [required('Campo obrigatório'), choices(['SOCIAL', 'GERAL'], 'Escolha o tipo da terapia')]

  return (
    <Edit title="Editar Serviço" {...props}>
      <SimpleForm>
        <TextInput disabled source="id" label="ID" />
        <TextInput validate={validateReq} source="name" label="Nome" />
        <ImageInput source="image" label="Thumbnail - (200x200)" accept="image/*" placeholder={<p> Arraste e solte ou clique para escolher a nova imagem </p>}>
            <ImageField source="image" label="Thumbnail" />
        </ImageInput>
        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem Original">
                            <ImageField source="image_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <TextInput multiline validate={validateReq} source="description" label="Descrição" />
        <TextInput multiline source="indications_benefits" label="Indicações e beneficios"/>
        <SelectArrayInput source="tags" title="Tags" choices={tagChoices} />
        <SelectInput validate={validateType} source="type" label="Tipo" choices={[
            { id: 'SOCIAL', name: 'SOCIAL' },
            { id: 'GERAL', name: 'GERAL' },
        ]} />
        <SelectInput validate={validateStatus} source="status" label="Status" choices={[
            { id: 'Ativo', name: 'Ativo' },
            { id: 'Inativo', name: 'Inativo' },
        ]} />
      </SimpleForm>
    </Edit>
  );
};

export default ServiceEdit;
