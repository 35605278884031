import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  DeleteButton,
  Filter,
  SelectInput,
  TextInput,
  DateField,
  DateInput,
  ReferenceField
} from "react-admin";

const SugestionsList = (props) => {

  const ListFilter = props => (
    <Filter {...props}>
        <SelectInput source="category" label="Categoria" choices={[
            { id: 'Terapia', name: 'Terapia' },
            { id: 'Conteúdo', name: 'Conteúdo' },
            { id: 'Sugestão', name: 'Sugestão' },
            { id: 'Assunto', name: 'Assunto' },
            { id: 'Elogio', name: 'Elogio' },
            { id: 'Reclamação', name: 'Reclamação' },
        ]} />
        <DateInput source="createdAt" locales="fr-FR" label="Publicado em"/>
    </Filter>
  );

  return (
    <List title="Lista de sugestões e reclamações" filters={<ListFilter />} {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="Id" />
        <ReferenceField source="client" label="Cliente" reference="users">
            <TextField source="name"/>
        </ReferenceField>
        <ChipField source="category" label="Categoria" />
        <DateField locales="fr-FR" source="createdAt" label="Publicado em"/>
      </Datagrid>
    </List>
  );
};

export default SugestionsList;
