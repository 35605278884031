import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
  NumberField,
  DateField,
  BooleanField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  FunctionField
} from "react-admin";

const translateType = (type) => {
  switch (type){
    case "PERCENTAGE":
      return "Porcentagem(%)";
    case "VALUE":
      return "Valor($)";
    default:
      return "";
  }
}

const CouponShow = (props) => {

  const Title = ({ record }) => {
    return <span>Cupom {record ? `"${record.code}"` : ''}</span>;
  };

  const ShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <ListButton basePath={basePath} record={data} />
        <EditButton basePath={basePath} record={data} />
        <DeleteButton basePath={basePath} record={data} />
        <RefreshButton basePath={basePath} record={data} />
    </CardActions>
  );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout rowClick={'edit'}>
        <TextField source="id" label="ID" />
        <TextField source="code" label="Código" />
        <NumberField source="discount" label="Desconto"/>
        <FunctionField label="Tipo de desconto" render={(record) => translateType(record.discount_type)} />
        <NumberField source="reusable_rate" label="Utilização máxima"/>
        <NumberField source="use_rate" label="Utilizações"/>
        <ReferenceManyField reference="services" target="id" source="services" label="Terapias válidas">
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField reference="users" target="id" source="professionals" label="Profissionais válidos">
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceManyField>
        <DateField locales="pt-BR" source="valid_until" label="Válido Até" />
        <BooleanField source="available" label="Status"/>
      </SimpleShowLayout>
    </Show>
  );
};

export default CouponShow;
  