import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
  UrlField,
  ArrayField,
  BooleanField,
  Datagrid
} from "react-admin";

const EventShow = (props) => {

    const Title = ({ record }) => {
        return <span>Evento {record ? `"${record.name}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <EditButton basePath={basePath} record={data} />
            <DeleteButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );

    return (
      <Show title={<Title />} actions={<ShowActions />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="ID do Evento" />
          <TextField source="name" label="Nome do Evento" />
          <TextField source="type" label="Presencial ou Online" />
          <DateField locales="pt-BR" showTime source="date" label="Data e horário de início" />
          <DateField locales="pt-BR" showTime source="conclusion" label="Data e horário de término" />
          <DateField locales="pt-BR" showTime source="sold_until" label="Data e horário limite de venda" />
          <DateField locales="pt-BR" showTime source="valid_until" label="Validade" />
          <TextField source="address" label="Endereço" />
          <TextField source="organizer" label="Organizador" />
          <TextField source="status" label="Status" />
          <TextField source="type" label="Tipo" />
          <NumberField source="max_clients" label="Maximo de clientes" />
          <NumberField source="scheduled_clients" label="Clientes agendados" />
          <ArrayField source="eventTicketTypes" label="Tipos de ingressos" fieldKey="id" >
            <Datagrid>
              <TextField source="name" label="Nome do ingresso"/>
              <NumberField source="quantity" label="Quantidade" />
              <NumberField source="price" label="Preço" />
              <DateField locales="pt-BR" showTime source="initial_sell_date" label="Data de início das vendas" />
              <DateField locales="pt-BR" showTime source="sold_until" label="Data de término das vendas" />
              <TextField source="description" label="Descrição" />
              <BooleanField source="available" label="Disponível" />
            </Datagrid>
          </ArrayField>
          <NumberField source="price" label="Preço para o cliente" />
          <DateField locales="pt-BR" showTime source="with_discount_until" label="Data limite de desconto"/>
          <TextField source="discount" label="Desconto"/>
          <TextField source="discount_type" label="Tipo do desconto"/> 
          <NumberField source="contract_price" label="Preço contratado" />
          <UrlField source="zoom_url" label="Url para sala do Zoom"/>
          <UrlField source="video" label="Video de apresentação"/>
        </SimpleShowLayout>
      </Show>
    );
  };
  
  export default EventShow;
  