import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  ListButton,
  RefreshButton,
  NumberField,
  BooleanField
} from "react-admin";

const PaymentShow = (props) => {

    const Title = ({ record }) => {
        return <span>Pagamento {record ? `"${record.id}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID do pagamento" />
        <TextField locales="pt-BR" source="date_created" label="Data da criação" />
        <TextField source="customer" label="ID do cliente na Asaas" />
        <TextField source="due_date" label="Data de vencimento" />
        <NumberField source="value" label="Total" />
        <NumberField source="net_value" label="Valor após taxas do Asaas" />
        <TextField source="billing_type" label="Meio de pagamento" />
        <TextField source="status" label="Status do pagamento" />
        <TextField source="description" label="Descrição do produto" />
        <TextField source="external_reference" label="ID do usuário no Mundo Akar" />
        <TextField source="confirmed_date" label="Data de confirmação" />
        <NumberField source="original_value" label="Valor original da cobrança (quando aplicada multa)" />
        <TextField source="interest_value" label="Valor devido quando o pagamento for realizado após vencimento"/>
        <TextField source="original_due_date" label="Vencimento no ato da criação da cobrança"/>
        <TextField source="payment_date" label="Data de liquidação da cobrança no Asaas"/>
        <TextField source="client_payment_date" label="Data em que o cliente efetuou o pagamento do boleto" />
        <TextField source="invoice_url" label="Link de pagamento da fatura/cobrança" />
        <TextField source="bank_slip_url" label="Link do Boleto (quando aplicado)" />
        <TextField source="invoice_number" label="Número da fatura/cobrança" />
        <BooleanField source="deleted" label="Cobrança foi excluída?" />
        <BooleanField source="anticipated" label="Cobrança antecipada?" />
        <BooleanField source="postalService" label="Cobrança enviada por correio?" />
      </SimpleShowLayout>
    </Show>
  );
};

export default PaymentShow;
