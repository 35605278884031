import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  DeleteButton,
  Filter, 
  TextInput,
  SelectInput,
  FunctionField
} from "react-admin";

const translateRole = (role) => {
  switch (role){
    case "client":
      return "Cliente";
    case "admin":
      return "Admin";
    case "professional":
      return "Profissional";
    case "company":
      return "Empresa";
    case "manager":
      return "Gerente";
    case "secretary":
      return "Secretário(a)";
    default:
      return "Função";
  }
}

const UserList = (props) => {

  const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="name" label="Nome" />
        <TextInput source="last_name" label="Sobrenome" />
        <SelectInput source="role" label="Função" choices={[
            { id: 'client', name: 'Clientes' },
            { id: 'professional', name: 'Profissionais' },
        ]}/>
        <TextInput source="email" label="Email"/>
    </Filter>
  );

  const StatusField = (record) => {
    return (
      <Typography color={record?.deleted_at ? "textSecondary" : "secondary"}>
        {record?.deleted_at ? 'Removido' : 'Ativo'}
      </Typography>
    )
  }

  return (
    <List title="Listar usuários" filters={<ListFilter/>} {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="name" label="Nome" />
        <EmailField source="email" label="Email" />
        <FunctionField
          label="Nascimento"
          render={(record) => {
            if(record.birth === ''){
              return (
                <span>Não informada</span>
              )
            }
            return (
              <span>{new Date(record.birth).toLocaleDateString('pt-br')}</span>
            )
          }}
        />
        <FunctionField label="Função" render={(record) => translateRole(record.role)} />
        <FunctionField label="Status" render={StatusField} />
        <FunctionField render={(record) => <EditButton basePath="/users" record={record} disabled={record?.deleted_at} />} />
        <FunctionField render={(record) => record?.deleted_at ? null : <DeleteButton record={record} basePath="/users" />} />
      </Datagrid>
    </List>
  );
};

export default UserList;
