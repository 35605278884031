import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Button, TextField } from '@material-ui/core';
import { useNotify } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';
import { Constants } from '../../../../constants';

import './styles.css';

const RemoveClientFromPlan = () => {

    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();

    const [clients, setClients] = useState([]);
    const [plans, setPlans] = useState([]);

    const [selectedClient, setSelectedClient] = useState();
    const [selectedPlan, setSelectedPlan] = useState();

    const getClients = async () => {

        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                range: "[0, 15000]",
                sort: `["name", "ASC"]`,
                filter: {}
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/users`, config)
            .then((response) => {
                setClients(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const getPlans = async (clientId) => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.get(`${Constants.ApiUrl}/plans/user/${clientId}`, config)
            .then((response) => {
                const data = response.data;
                const auxPlans = data.map((userPlan) => {
                    return userPlan.plan
                })
                
                setPlans(auxPlans);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleSelectedClient = async (client) => {
        setSelectedClient(client);
        if(client){
            getPlans(client.id);
        }
    }

    const handleSelectedPlan = (plan) => {
        setSelectedPlan(plan ? plan.id : null);
    }

    const handleSubmitRemoveClientFromPlan = async () => {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {

            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await Axios.post(`${Constants.ApiUrl}/plans/admin/removeclient`, {
            clientId: selectedClient.id,
            planId: selectedPlan
        } ,config)
            .then((response) => {
                notify(response.data.message, 'info', {});
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notify(err.response.data.message, 'warning', {});
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getClients();
    }, [])

    return (
        <div id="add-client" >
            <header>
                <h1>Abilitar ou fazer upgrado de plano para o cliente</h1>
            </header>
            <section>
                <h2>Selecionar cliente</h2>
                <Autocomplete
                    id="select-client"
                    options={clients}
                    loading={isLoading}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    style={{width: 300}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Cliente"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedClient(value)}}
                />
            </section>
            <section>
                <h2>Selecionar plano</h2>
                <Autocomplete
                    id="select-plan"
                    options={plans}
                    loading={isLoading}
                    getOptionLabel={(option) => option.name}
                    style={{width: 300}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Plano"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedPlan(value)}}
                />
            </section>
            <div className="button-box">
                <Button disabled={!selectedClient || !selectedPlan} onClick={() => {handleSubmitRemoveClientFromPlan()}}>Confirmar</Button>
            </div>
        </div>
    )
}

export default RemoveClientFromPlan;