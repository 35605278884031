import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';

import { Constants } from '../../../constants';
import axios from 'axios';

import './styles.css';

const SocialReports = () => {

    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);

    const handleInitialDateChange = (date) => {
      setSelectedInitialDate(date);
    };
    const handleFinalDateChange = (date) => {
        setSelectedFinalDate(date);
    };

    const hadleDownloadReport = async () => {
        const isoInitialDate = new Date(selectedInitialDate).toISOString();
        const isoFinalDate = new Date(selectedFinalDate).toISOString();

        const initialDateString = `${new Date(selectedInitialDate).getDate()}${(new Date(selectedInitialDate).getMonth()) + 1}${new Date(selectedInitialDate).getFullYear()}`;
        const finalDateString = `${new Date(selectedFinalDate).getDate()}${(new Date(selectedFinalDate).getMonth()) + 1}${new Date(selectedFinalDate).getFullYear()}`;

        const fileName = `relatorio-x-${initialDateString}-${finalDateString}.xlsx`;

        const user = JSON.parse(localStorage.getItem('auth'));
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await axios
            .get(`${Constants.ApiUrl}/reports/social-schedules/general?initial=${isoInitialDate}&final=${isoFinalDate}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                console.log(err);
            }))
    }

    return (
        <div id="relatorios" >
            <header>
                <h1>Relatórios</h1>
            </header>
            <section className="reports-area">
                <h2>Clientes agendados Mundo Akar Social
                </h2>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="pikers">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data Inicial"
                                    value={selectedInitialDate}
                                    onChange={handleInitialDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="date-piker"
                                />
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data Final"
                                    value={selectedFinalDate}
                                    onChange={handleFinalDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="date-piker"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudDownloadIcon/>}
                                    onClick={hadleDownloadReport}
                                    onLoad={isLoading}
                                >
                                    Gerar Relatório
                                </Button>
                                {isLoading &&
                                    <CircularProgress className="loading-spinner"/>
                                }
                        </div>
                    </MuiPickersUtilsProvider>
            </section>
        </div>
    )
}

export default SocialReports;