import React from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import { addField } from "ra-core";
import { Labeled } from "react-admin";
import { useInput } from "react-admin";
import { Constants } from "../../constants";
import axios from "axios";

function TinyMCEInput(props) {
  const {
    input,
    meta: { touched, error },
    isRequired,
    ...rest
  } = useInput(props);

  function handleChange(eventOrValue) {
    props.onChange(eventOrValue.target.getContent());
    input.onChange(eventOrValue.target.getContent());
  }

  const value = input.value || props.defaultEmail || "";

  return (
    <Labeled label={props.label || ""} isRequired={isRequired}>
      <Editor
        init={props.init}
        initialValue={value}
        error={!!(touched && error)}
        plugins={props.plugins}
        toolbar={props.toolbar}
        onChange={(event) => handleChange(event)}
        {...rest}
      />
    </Labeled>
  );
}

TinyMCEInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  init: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  toolbar: PropTypes.string,
  menubar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  plugins: PropTypes.string,
};

TinyMCEInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  init: {
    // The auto_focus property doesn't actually work at this time.
    // See hackToDisableAutoFocusOnTinyMCE comments.
    auto_focus: false,
    height: 512,
    content_style: "a { color: #53B7E8; text-decoration: none }",
    plugins:
      "autolink code  fullscreen " +
      "image media link lists paste " +
      "preview table  help",
    menubar: "view edit insert format tools table help", // See https://www.tiny.cloud/docs/advanced/editor-control-identifiers/
    toolbar:
      "undo redo | " +
      "styleselect | " +
      "bold italic | " +
      "fontselect | fontsizeselect | " +
      "alignleft aligncenter alignright alignjustify | " +
      "bullist numlist outdent indent | " +
      "link image | " +
      "forecolor backcolor",
    // images_upload_handler: this.onEditorImageUpload,
    plugin_preview_height: 650, // default: 500,
    plugin_preview_width: 845, // default: 650,
    style_formats: [
      { title: "Subtitle", block: "h3" },
      {
        title: "Button",
        inline: "span",
        styles: {
          "background-color": "#53B7E8",
          "border-radius": "5px",
          color: "white",
          display: "inline-block",
          padding: "12px 8px",
        },
      },
    ],
    branding: false,
    //images_upload_url: "postAcceptor.php",
    language: "pt_BR",
    images_upload_handler: async (blob, success) => {
      const formData = new FormData();
      formData.append("image", blob.blob());

      const user = JSON.parse(localStorage.getItem('auth'));

      const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        },
      }

      const response = await axios.post(`${Constants.ApiUrl}/posts/image`,
        formData,
        config
      );

      success(response.data.url);
    },
  },
  toolbar: "",
  menubar: true,
  plugins: "",
  label: "",
};

export default addField(TinyMCEInput);
