import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  BooleanField,
} from "react-admin";
const InvoiceTaxList = (props) => {
  return (
    <List title="Listar Taxas de Notas fiscais" {...props} sort={
        { field: "category", order: "ASC" }
    }>
      <Datagrid rowClick={"show"}>
        <BooleanField source="retain_iss" label="Reter ISS" />
        <NumberField source="iss" label="ISS" />
        <NumberField source="cofins" label="Cofins" />
        <NumberField source="csll" label="CSLL" />
        <NumberField source="ir" label="IR" />
        <NumberField source="inss" label="INSS" />
        <NumberField source="pis" label="PIS" />
        <TextField source="municipal_service_id" label="ID Serviço Municipal" />
        <TextField
          source="municipal_service_code"
          label="Código Serviço Municipal"
        />
        <TextField
          source="municipal_service_name"
          label="Nome Serviço Municipal"
        />
        <TextField source="category" label="Categoria" />
        <BooleanField source="active" label="Emissão ativa" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default InvoiceTaxList;
