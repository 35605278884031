import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ImageInput,
  ImageField,
  SelectInput,
  Labeled,
  FormDataConsumer,
  required,
  email,
  choices,
  minLength,
  minValue,
  regex,
  BooleanInput
} from "react-admin";

const UserEdit = (props) => {

const validateName = [required('Campo obrigatório'), minLength(2, 'Mínimo dois caracteres')];
const validateLastName = [required('Campo obrigatório'), minLength(2, 'Mínimo dois caracteres')];
const validateEmail = [required('Campo obrigatório'), email('Email inválido')];
const validateGender = choices(['Masculino', 'Feminino'], 'Escolha o gênero');
const validateStreet = minValue(1, 'Valor menor que 1 não permitido');
const validateRole = choices(['client', 'professional', 'admin', 'company', 'manager', 'secretary'], 'Escolha a função');
const validateZipCode = [regex(/^([\d]{2})\.*([\d]{3})-([\d]{3})/, 'Digite um CEP válido')];
const validateCPFCNPJ = [regex(/^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/, 'Digite um CPF ou CNPJ válido')]

  return (
    <Edit title="Editar usuário" {...props}>
      <SimpleForm>
        <ImageInput source="image" label="Imagem de perfil" accept="image/*" placeholder={<p> Arraste e solte a nova imagem aqui </p>}>
            <ImageField source="image" title="Foto de perfil" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem de perfil">
                            <ImageField source="image_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <TextInput validate={validateName} source="name" label="Nome" />
        <TextInput validate={validateLastName} source="last_name" label="Sobrenome" />
        <DateInput locales="pt-BR" source="birth" label="Data de nascimento" />
        <TextInput validate={validateEmail} source="email" label="Email" />
        <TextInput source="phone" label="Telefone" />
        <TextInput validate={validateCPFCNPJ} source="cpf_cnpj" label="CPF ou CNPJ" />
        <TextInput validate={validateZipCode} source="zip_code" label="CEP" />
        <TextInput source="street" label="Rua" />
        <TextInput validate={validateStreet} source="street_number" label="Número" />
        <TextInput source="complement" label="Complemento" />
        <TextInput source="neighborhood" label="Bairro" />
        <TextInput source="city" label="Cidade" />
        <TextInput source="state" label="Estado" />
        <SelectInput validate={validateGender} source="gender" label="Gênero" choices={[
            { id: 'Masculino', name: 'Masculino' },
            { id: 'Feminino', name: 'Feminino' },
        ]} />
        <SelectInput validate={validateRole} source="role" label="Função" choices={[
            { id: 'client', name: 'Cliente' },
            { id: 'professional', name: 'Profissional' },
            { id: 'admin', name: 'Administrador' },
            { id: 'company', name: 'Empresa' },
            { id: 'manager', name: 'Gerente'},
            { id: 'secretary', name: 'Secretária' }
        ]} />
        <TextInput source="obs" label="Observações" multiline="true"/>
        <TextInput source="specialties" label="Especialidade(s)"/>
        <TextInput source="crp_crm_trt" label="CRP/CRM/TRT"/>
        <TextInput source="mini_curriculum" label="Mini Currículo" multiline="true"/>
        <TextInput source="company_name" label="Nome Fantasia"/>
        <TextInput source="company_field" label="Área da empresa"/>
        <TextInput source="employee_quantity" label="Quantidade de funcionários"/>
        <TextInput source="business_contact" label="Contato comercial"/>
        <TextInput source="financial_contact" label="Contato financeiro" />
        <TextInput source="asaas_api_key" label="API Key Asaas" />
        <TextInput source="zoom_email" label="Email Zoom" />
        <TextInput source="zoom_api_key" label="API Key Zoom" />
        <TextInput source="zoom_api_secret" label="API Secret" />
        <BooleanInput source="active" label="Ativo"/>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
