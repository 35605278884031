import React, { useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  Labeled,
  FormDataConsumer,
  useEditContext,
  useInput,
} from "react-admin";
import TinyMCEInput from "../helpers/TinyMCEInput";
import { useRooms } from "./useRooms";
import { Rooms } from "./Rooms";

const Form = () => {
  const { record } = useEditContext();
  const { input } = useInput({ source: "rooms_set" });
  const props = useRooms();
  const { setSelectedRooms, selectedRooms } = props;

  useEffect(() => {
    if (record?.rooms) {
      setSelectedRooms(record.rooms.map((room) => room.id));
    }
  }, [setSelectedRooms, record]);

  useEffect(() => {
    input.onChange(selectedRooms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRooms]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextInput source="name" label="Nome" />
      <TinyMCEInput source="description" label="Descrição" />
      <TextInput source="weight" label="Ordem do cabeçalho" defaultValue={0} type="number" />

      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
          if (!formData.src) {
            return (
              <div>
                <Labeled label="Imagem atual">
                  <ImageField
                    record={record}
                    source="main_image_url"
                    {...rest}
                  />
                </Labeled>
              </div>
            );
          }
        }}
      </FormDataConsumer>
      <ImageInput
        source="main_image"
        label="Image"
        accept="image/*"
        placeholder={
          <p> Arraste e solte ou clique para inserir uma nova imagem </p>
        }
      >
        <ImageField source="main_image" title="Imagem" />
      </ImageInput>
      <Rooms {...props} />
    </div>
  );
};

const PageEdit = (props) => {
  return (
    <Edit title="Editar página" {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Edit>
  );
};

export default PageEdit;
