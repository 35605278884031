import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton
} from "react-admin";

const ServiceShow = (props) => {

    const Title = ({ record }) => {
        return <span>Serviço {record ? `"${record.name}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <EditButton basePath={basePath} record={data} />
            <DeleteButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );
  
    return (
      <Show title={<Title />} actions={<ShowActions />} {...props}>
        <SimpleShowLayout rowClick={'edit'}>
          <TextField source="id" label="Id" />
          <TextField source="name" label="Nome" />
          <TextField source="description" label="Descrição" />
          <ImageField source="image_url" label="Thumbnail"/>
          <TextField source="status" label="Status" />
          <TextField source="type" label="Tipo" />
        </SimpleShowLayout>
      </Show>
    );
  };
  
  export default ServiceShow;
  