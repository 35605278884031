import React, { useState, useEffect, useCallback } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SaveButton,
  Toolbar,
  SelectArrayInput,
  ImageField,
  ImageInput,
  required,
  NumberInput,
  DateTimeInput,
  minValue,
  choices,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
} from "react-admin";
import TinyMCEInput from "../helpers/TinyMCEInput";
import TagQuickCreateButton from "../custom/TagQuickCreateButton";
import axios from 'axios';
import { Constants } from '../../constants';

const EventCreate = (props) => {

  const [tagChoices, setTagChoices] = useState([]);
  const [professionalChoices, setProfessionalChoices] = useState([]);

  const getTags =  useCallback( async () => {
    try{
        const response = await axios.get(Constants.ApiUrl + '/tags', { params: { range: '[0,1000]' } });
        const auxServices = response.data;
        const transformedTags = auxServices.map( (tag) => {
            return { id: tag.name, name: `${tag.name}` }
        })
        setTagChoices(transformedTags);
    }catch(err){
        console.log(err);
    }         
  }, []);

  const getProfessionals = useCallback( async () => {
    try{
        const response = await axios.get(Constants.ApiUrl + '/users/professionals');
        
        const auxProfessionals = response.data;

        const transformedProfessionals = auxProfessionals.map((professional) => {
            return { id: professional.id, name: `${professional.name} ${professional.last_name}` };
        });

        setProfessionalChoices(transformedProfessionals);

    }catch(err){
        console.log(err);
    }
}, []);

  useEffect(() => {
      getTags();
      getProfessionals();
  }, [getTags,getProfessionals])

  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar"/>
    </Toolbar>
  );

  const validateReq = required('Campo obrigatório');
  const validatePrice = [required('Campo obrigatório'), minValue(0, 'Valor mínimo R$0')];
  const validateMinClients = [required('Campo obrigatório'), minValue(1, 'Mínimo 1 participante')];
  const validateType = choices(['Presencial', 'Online'], 'Escolha o tipo');
  const validateDiscount = [minValue(1, 'Não pode ser negativo')];
  const validateDiscountType = choices(['PERCENTAGE', 'VALUE'], 'Escolha o tipo');

  return (
    <Create title="Novo Evento" {...props}>
      <SimpleForm redirect="list" toolbar={<TranslatedToolbar/>}>
        <TextInput validate={validateReq} source="name" label="Nome" />
        <DateTimeInput validate={validateReq} locales="pt-BR" source="date" label="Data e hora do início do evento" />
        <DateTimeInput validate={validateReq} locales="pt-BR" source="conclusion" label="Data e hora da conclusão do evento" />
        <DateTimeInput validate={validateReq} locales="pt-BR" source="sold_until" label="Limite para venda" />
        <DateTimeInput validate={validateReq} locales="pt-BR" source="valid_until" label="Validade" />
        <ImageInput validate={validateReq} source="thumbnail" label="Thumbnail" accept="image/*" placeholder={<p> Arraste e solte ou clique para escolher a imagem </p>}>
            <ImageField source="thumbnail" title="Thumbnail" />
        </ImageInput>
        <TextInput source="organizer" label="Organizador" />
        <NumberInput validate={validateMinClients} source="max_clients" label="Máximo de clientes" />
        <SelectInput validate={validateType} source="type" label="Presencial ou Online" choices={[
            { id: 'Presencial', name: 'Presencial' },
            { id: 'Online', name: 'Online' },
        ]} />
        <NumberInput validate={validatePrice} source="price" label="Preço para o cliente" />
        <DateTimeInput locales="pt-BR" source="with_discount_until" label="Data limite para desconto" />
        <SelectInput validate={validateDiscountType} source="discount_type" label="Tipo de desconto" choices={[
          { id: 'PERCENTAGE', name: 'Porcentagem (%)' },
          { id: 'VALUE', name: 'Valor (R$)' }
        ]}/>
        <NumberInput validate={validateDiscount} source="discount" label="Desconto"/>
        <ArrayInput source="eventTicketTypes" label="Tipos de ingressos" >
          <SimpleFormIterator>
            <TextInput validate={validateReq} source="name" label="Nome do ingresso"/>
            <NumberInput validate={validateReq} source="quantity" label="Quantidade" />
            <NumberInput validate={validatePrice} source="price" label="Preço" />
            <DateTimeInput validate={validateReq} locales="pt-BR" source="initial_sell_date" label="Data de início das vendas" />
            <DateTimeInput validate={validateReq} locales="pt-BR" source="sold_until" label="Data de término das vendas" />
            <TextInput source="description" label="Descrição" />
            <BooleanInput source="available" defaultValue={true} label="Disponível" />
          </SimpleFormIterator>
        </ArrayInput>
        <NumberInput validate={validateReq} source="contract_price" label="Preço contratado" />
        <TextInput source="zoom_url" label="URL do ZOOM" />
        <TextInput source="address" label="Endereço" />
        <TinyMCEInput source="description" label="Descrição" validate={validateReq} />
        <div style={{display: "flex", flexDirection: "row"}}>
          <SelectArrayInput label="Tags" source="tags" choices={tagChoices}/>
          <TagQuickCreateButton onChange={() => getTags()}/>
        </div>
        <SelectArrayInput label="Profissionais Relacionados" source="professionals" choices={professionalChoices} />
        <SelectInput source="category" label="Categoria" choices={[
            { id: 'e-books', name: 'Ebooks' },
            { id: 'palestras', name: 'Palestras' },
            { id: 'eventos', name: 'Eventos' },
            { id: 'filmes', name: 'Filmes' },
            { id: 'solidariedade', name: 'Solidariedade' },
            { id: 'empresarios', name: 'Empresários' },
            { id: 'projetos', name: 'Projetos' },
            { id: 'kids', name: 'Kids' },
            { id: 'espaços', name: 'Espaços' },
            { id: 'salas', name: 'Salas' },
            { id: 'depoimentos', name: 'Depoimentos' },
            { id: 'cafe', name: 'Café' },
        ]} />
      </SimpleForm>
    </Create>
  );
};

export default EventCreate;
