import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  SelectInput,
  TextInput,
  Filter,
  DateInput
} from "react-admin";

const OrderList = (props) => {

  const statusChoices = [
    { id: 'PENDENTE', name: 'PENDENTE' },
    { id: 'CANCELADO', name: 'CANCELADO' },
    { id: 'CONFIRMADO', name: 'CONFIRMADO'}
  ]

  const typeChoices = [
    { id: 'Geral', name: 'Geral' },
    { id: 'Social', name: 'Social' }
  ]

  const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="client_name" label="Nome do cliente" />
        <TextInput source="client_email" label="Email do cliente" />
        <DateInput source="createdAt" label="Data do pedido" />
        <SelectInput source="status" label="Status" choices={statusChoices} />
        <SelectInput source="type" label="Tipo" choices={typeChoices} />
    </Filter>
  );

  return (
    <List title="Lista de pedidos" filters={<ListFilter/>}{...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="ID do pedido" />
        <ReferenceField source="client" label="Cliente" reference="users">
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="payment_id" label="ID do pagamento" reference="payments">
            <TextField source="id"/>
        </ReferenceField>
        <TextField source="status" label="Status" />
        <NumberField source="value" label="Total" />
        <TextField source="type" label="Tipo"/>
        <DateField locales="pt-BR" source="createdAt" label="Data do pedido" />
      </Datagrid>
    </List>
  );
};

export default OrderList;
