const gridTranslations = {

    noRowsLabel: 'Nenhuma linha',
    noResultsOverlayLabel: 'Nenhum resultado encontrado.',
    errorOverlayDefaultLabel: 'Ocorreu um erro.',

    // Density selector toolbar button text
    toolbarDensity: 'Densidade',
    toolbarDensityLabel: 'Densidade',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Normal',
    toolbarDensityComfortable: 'Confortável',

    // Columns selector toolbar button text
    toolbarColumns: 'Colunas',
    toolbarColumnsLabel: 'Colunas selecionadas',

    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Esconder filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

    // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Download como CSV',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Encontrar coluna',
    columnsPanelTextFieldPlaceholder: 'Título',
    columnsPanelDragIconLabel: 'Reordenar',
    columnsPanelShowAllButton: 'Mostrar tudo',
    columnsPanelHideAllButton: 'Esconder tudo',

    // Filter panel text
    filterPanelAddFilter: 'Adcionar filtro',
    filterPanelDeleteIconLabel: 'Excluir',
    filterPanelOperators: 'Operadores',
    filterPanelOperatorAnd: 'e',
    filterPanelOperatorOr: 'ou',
    filterPanelColumns: 'Colunas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Filtrar valor',

    // Filter operators text
    filterOperatorContains: 'contém',
    filterOperatorEquals: 'igual',
    filterOperatorStartsWith: 'começa com',
    filterOperatorEndsWith: 'termina com',
    filterOperatorIs: 'é',
    filterOperatorNot: 'não é',
    filterOperatorAfter: 'é depois',
    filterOperatorOnOrAfter: 'é em ou depois',
    filterOperatorBefore: 'é antes',
    filterOperatorOnOrBefore: 'é em ou antes',
    filterOperatorIsEmpty: 'é vazio',
    filterOperatorIsNotEmpty: 'não é vazio',

    // Filter values text
    filterValueAny: 'any',
    filterValueTrue: 'true',
    filterValueFalse: 'false',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostrar colunas',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Esconder',
    columnMenuUnsort: 'Desorganizar',
    columnMenuSortAsc: 'Organizar por ASC',
    columnMenuSortDesc: 'Organizar por DESC',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Organizar',

    // Rows selected footer text
    footerRowSelected: (count) =>
    count !== 1
        ? `${count.toLocaleString()} selecionados`
        : `${count.toLocaleString()} selecionado`,

    // Total rows footer text
    footerTotalRows: 'Total:',
    labelRowsPerPage: 'Linhas por página:',

    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Seleções',

    // Boolean cell text
    booleanCellTrueLabel: 'true',
    booleanCellFalseLabel: 'false',

    // Actions cell more text
    actionsCellMore: 'mais...',
  }

export { gridTranslations };