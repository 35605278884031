import React, { useCallback, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  Labeled,
  FormDataConsumer,
  required,
} from "react-admin";


const GaleryEdit = (props) => {

  const validateReq = required('Campo obrigatório');

  return (
    <Edit title="Editar galeria" {...props}>
      <SimpleForm>
        <TextInput multiline source="main_text" label="Texto principal" />
        <TextInput  source="main_video_url" label="URL do video principal" />
        <TextInput  source="video2_url" label="URL do video 2" />
        <TextInput  source="video3_url" label="URL do video 3" />
        <TextInput  source="video4_url" label="URL do video 4" />
        <TextInput  source="video5_url" label="URL do video 5" />

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 1 atual">
                            <ImageField source="image1_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image1" label="Nova Imagem 1" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image1" title="Nova Imagem 1" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 2 atual">
                            <ImageField source="image2_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image2" label="Nova Imagem 2" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image2" title="Nova Imagem 2" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 3 atual">
                            <ImageField source="image3_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image3" label="Nova Imagem 3" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image3" title="Nova Imagem 3" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 4 atual">
                            <ImageField source="image4_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image4" label="Nova Imagem 4" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image4" title="Nova Imagem 4" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 5 atual">
                            <ImageField source="image5_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image5" label="Nova Imagem 5" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image5" title="Nova Imagem 5" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 6 atual">
                            <ImageField source="image6_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image6" label="Nova Imagem 6" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image6" title="Nova Imagem 6" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export default GaleryEdit;