import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  TextInput,
  Filter,
  DateInput,
  SelectInput,
  UrlField
} from "react-admin";

const PaymentList = (props) => {

  const statusChoices = [
    { id: 'PENDING', name: 'Pendente' },
    { id: 'RECEIVED', name: 'Recebido' },
    { id: 'CONFIRMED', name: 'Confirmado'}
  ]

  const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="client_name" label="Nome do cliente" />
        <TextInput source="client_email" label="Email do cliente" />
        <DateInput source="date_created" label="Data da criação" />
        <SelectInput source="status" label="Status" choices={statusChoices} />
    </Filter>
  );

  return (
    <List title="Listar usuários" filters={<ListFilter/>} {...props}>
        <Datagrid rowClick={'show'}>
            <TextField source="id" label="ID do pagamento" />
            <TextField locales="pt-BR" source="date_created" label="Data da criação" />
            <TextField source="customer" label="ID do cliente na Asaas" />
            <TextField source="due_date" label="Data de vencimento" />
            <NumberField source="value" label="Total" />
            <NumberField source="net_value" label="Valor após taxas" />
            <TextField source="billing_type" label="Meio de pagamento" />
            <TextField source="status" label="Status do pagamento" />
            <ReferenceField source="external_reference" label="Cliente" reference="users">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="confirmed_date" label="Data de confirmação" />
            <TextField source="payment_date" label="Data de liquidação da cobrança no Asaas"/>
            <TextField source="client_payment_date" label="Data do pagamento" />
            <UrlField source="invoice_url" label="Link da fatura/cobrança" />
            <TextField source="bank_slip_url" label="Link do Boleto" />
            <TextField source="invoice_number" label="Número da fatura/cobrança" />
        </Datagrid>
    </List>
  );
};

export default PaymentList;
