import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Constants } from '../../../constants';
import axios from 'axios';

import './styles.css';

const ProfessionalReports = () => {

    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [professionals, setProfessionals] = useState();
    const [selectedProfessional, setSelectedProfessional] = useState();
    const [reportType, setReportType] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const reportTypeOptions = [
        { type: 'commission', name: 'Comissão'},
        { type: 'services', name: 'Serviços e Planos' }
    ]

    const handleInitialDateChange = (date) => {
      setSelectedInitialDate(date);
    };
    const handleFinalDateChange = (date) => {
        setSelectedFinalDate(date);
    };

    const getProfessionals = async () => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('auth'));

        const config = {
            params: {
                // filter: {
                //     "role": "professional"
                // }
            },
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await axios.get(`${Constants.ApiUrl}/users/professionals`, config)
            .then((response) => {
                setProfessionals(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const hadleDownloadReport = async () => {
        const isoInitialDate = new Date(selectedInitialDate).toISOString();
        const isoFinalDate = new Date(selectedFinalDate).toISOString();

        const initialDateString = `${new Date(selectedInitialDate).getDate()}${(new Date(selectedInitialDate).getMonth()) + 1}${new Date(selectedInitialDate).getFullYear()}`;
        const finalDateString = `${new Date(selectedFinalDate).getDate()}${(new Date(selectedFinalDate).getMonth()) + 1}${new Date(selectedFinalDate).getFullYear()}`;

        const fileName = `relatorio-x-${initialDateString}-${finalDateString}.xlsx`;

        const user = JSON.parse(localStorage.getItem('auth'));
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await axios
            .get(`${Constants.ApiUrl}/reports/professional?initial=${isoInitialDate}&final=${isoFinalDate}&professional=${selectedProfessional}&type=${reportType}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                console.log(err);
            }))
    }

    const handleSelectedProfessional = (professional) => {
        setSelectedProfessional(professional ? professional.id : null);
    }

    const handleSelectedReportType = (report) => {
        setReportType(report ? report.type : null);
    }

    useEffect(() => {
        getProfessionals();
    }, [])

    return (
        <div id="relatorios" >
            <header>
                <h1>Relatórios</h1>
            </header>
            <section className="reports-area">
                <h2>Selecionar tipo do relatório </h2>
                <Autocomplete
                    id="select-reportType"
                    options={reportTypeOptions}
                    getOptionLabel={(option) => `${option.name}`}
                    style={{width: 300}}
                    loading={isLoading}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Tipo do Relatório"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedReportType(value)}}
                />
                <h2>Selecionar Profissional</h2>
                <Autocomplete
                    id="select-professional"
                    options={professionals}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    style={{width: 300}}
                    loading={isLoading}
                    disabled={isLoading || !professionals}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Profissional"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />}
                    onChange={(event, value) => {handleSelectedProfessional(value)}}
                />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="pikers">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data Inicial"
                                    value={selectedInitialDate}
                                    onChange={handleInitialDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="date-piker"
                                />
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data Final"
                                    value={selectedFinalDate}
                                    onChange={handleFinalDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="date-piker"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudDownloadIcon/>}
                                    onClick={hadleDownloadReport}
                                    onLoad={isLoading}
                                >
                                    Gerar Relatório
                                </Button>
                                {isLoading &&
                                    <CircularProgress className="loading-spinner"/>
                                }
                        </div>
                    </MuiPickersUtilsProvider>
            </section>
        </div>
    )
}

export default ProfessionalReports;