import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
  UrlField,
} from "react-admin";
const InvoiceList = (props) => {
  const translateStatus = (status) => {
    switch (status) {
      case "SCHEDULED":
        return "AGENDADA";
      case "SYNCHRONIZED":
        return "ENVIADA";
      case "AUTHORIZED":
        return "EMITIDA";
      case "CANCELLED":
        return "CANCELADA";
      case "ERROR":
        return "ERRO";
      default:
        return "";
    }
  };

  return (
    <List title="Listar Notas Fiscais" {...props}>
      <Datagrid rowClick={"show"}>
        <FunctionField
          label="Status"
          render={(record) => translateStatus(record.status)}
        />
        <TextField source="type" label="Tipo" />
        <TextField source="rpsNumber" label="Número" />
        <TextField source="number" label="Número" />
        <NumberField source="value" label="Valor" />
        <NumberField source="effectiveDate" label="Data de Emissão" />
        <UrlField source="pdfUrl" label="PDF"  />
        <ReferenceField reference="payments" source="payment" label="Pagamento">
          <TextField source="id" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default InvoiceList;
