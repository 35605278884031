import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  TextInput,
  SelectInput,
  Filter
} from "react-admin";

const ServiceList = (props) => {

  const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="name" label="Nome" />
        <SelectInput source="status" label="Status" choices={[
            { id: 'Ativo', name: 'Ativo' },
            { id: 'Inativo', name: 'Inativo' },
        ]}/>
        <TextInput source="description" label="Descrição"/>
    </Filter>
  );
  
  return (
    <List title="Listar serviços" filters={<ListFilter/>}  {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="Id" />
        <TextField source="name" label="Nome" />
        <TextField source="description" label="Descrição" />
        <TextField source="status" label="Status" />
        <TextField source="type" label="Tipo" />
        <EditButton basePath="/services" />
        <DeleteButton basePath="/services" />
      </Datagrid>
    </List>
  );
};

export default ServiceList;
