import React from "react";
import CardActions from '@material-ui/core/CardActions';

import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  ReferenceField,
  ImageField,
  ChipField,
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton,
} from "react-admin";

const SugestionsShow = (props) => {

    const Title = ({ record }) => {
        return <span>Sugestão {record ? `"${record.id}"` : ''}</span>;
    };

    const ShowActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ListButton basePath={basePath} record={data} />
            <RefreshButton basePath={basePath} record={data} />
        </CardActions>
    );

    return (
      <Show title={<Title />} actions={<ShowActions />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="Id" />
          <ReferenceField source="client" label="Cliente" reference="users">
            <TextField source="name"/>
            </ReferenceField>
          <RichTextField source="content" label="Texto" />
          <ChipField source="category" label="Categoria" />
        </SimpleShowLayout>
      </Show>
    );
  };
  
  export default SugestionsShow;
  