import React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  TextField,
  Tab,
  ReferenceManyField,
  Datagrid,
  DeleteButton
} from 'react-admin';

const CommentShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
        <Tab label="Comentário">
            <TextField source="id" label="ID" />
            <ReferenceField source="author" reference="users" label="Usuário" >
                    <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="post" reference="posts" label="Postagem">
                <TextField source="title" />
            </ReferenceField>
            <TextField source="content" label="comentário"/>
            <DateField source="createdAt" label="Data"/>
        </Tab>
        <Tab label="Respostas">
            <ReferenceManyField
              addLabel={false}
              reference="comments/replies"
              target="comment"
              sort={{ field: 'createdAt', order: 'DESC' }}
            >
              <Datagrid>
                <DateField source="createdAt" label="Data" />
                <ReferenceField source="author" label="Usuário" reference="users">
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="content" label="comentário" />
                <DeleteButton />
              </Datagrid>
            </ReferenceManyField>
        </Tab>
    </TabbedShowLayout>
  </Show>
);

export default CommentShow;
